import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import KeyValueRow from '../../components/key-value-row/key-value-row.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import WarehouseItems from '../../components/warehouse-items/warehouse-items.component';
import AuditInfo from '../../components/audit-info/audit-info.component';

const WarehouseDetailsScreen = ({ match }) => {
    const id = match.params.warehouseId;
    const { warehouses } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        warehouses.get(id, res => {
            setData(res);
        });
    }, [warehouses, id]);

    const editItem = () => {
        history.push("/warehouses/" + id + "/edit");
    };

    const addTrackingId = (trackingId) => {
        warehouses.addItem(id, trackingId, res => {
            setData(res);
            setError(null);
        }, err => {
            setError(err);
        });
    };

    const addEvent = (newStatus, message) => {
        warehouses.addEvent(id, { message, newStatus }, res => {
            setData(res);
            setError(null);
        }, err => {
            setError(err);
        })
    };

    return data ? (
        <ScreenPanel>
            <ScreenTitle label="Warehouse" />
            <KeyValueRow k="WarehouseId">{data.warehouseId}</KeyValueRow>
            <AuditInfo data={data} />
            <ButtonBar>
                <Button variant="primary" onClick={editItem}>{t("Edit")}</Button>
            </ButtonBar>
            <Tabs>
                <Tab eventKey="Pakete" title="Pakete">
                    <WarehouseItems warehouse={data} onAdd={addTrackingId} />
                </Tab>
            </Tabs>
        </ScreenPanel>
    ) : null;
};

export default WarehouseDetailsScreen;

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../common/server.component'
import ButtonBar from '../../components/button-bar/button-bar.component'
import Button from 'react-bootstrap/Button'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ContainerItems from '../../components/container-items/container-items.component'
import ContainerEvents from '../../components/container-events/container-events.component'
import ErrorMessage from '../../backend/ErrorMessage'
import { notify } from 'react-notify-toast'
import AuditInfo from '../../components/audit-info/audit-info.component'
import { Modal } from 'react-bootstrap'

const ContainerDetailsScreen = ({ match }) => {
  const id = match.params.containerId
  const { containers } = useContext(Server)
  const { t } = useTranslation()
  const history = useHistory()
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [showDelete, setShowDelete] = useState(false)

  useEffect(() => {
    containers.get(id, res => {
      setData(res)
    })
  }, [containers, id])

  const editItem = () => {
    history.push('/containers/' + id + '/edit')
  }

  const addTrackingId = trackingId => {
    containers.addItem(
      id,
      trackingId,
      res => {
        setData(res)
        setError(null)
        notify.show(t('Added'), 'success', 3000)
      },
      err => {
        setError(err)
      }
    )
  }

  const addEvent = (newStatus, message) => {
    containers.addEvent(
      id,
      { message, newStatus },
      res => {
        setData(res)
        setError(null)
        notify.show(t('Added'), 'success', 3000)
      },
      err => {
        setError(err)
      }
    )
  }

  const deleteContainer = () => {
    containers.delete(data.id, () => history.goBack(), console.error)
  }

  return data ? (
    <ScreenPanel>
      <ErrorMessage error={error} />
      <ScreenTitle label="Container" />
      <KeyValueRow k="ContainerId">{data.containerId}</KeyValueRow>
      <AuditInfo data={data} />
      <ButtonBar>
        <Button variant="secondary" disabled={data?.trackingIds?.length > 0} onClick={() => setShowDelete(true)}>
          {t('Delete')}
        </Button>
        <Button variant="primary" onClick={editItem}>
          {t('Edit')}
        </Button>
      </ButtonBar>
      <Tabs>
        <Tab eventKey="Pakete" title={t('Packages')}>
          <ContainerItems container={data} onAdd={addTrackingId} />
        </Tab>
        <Tab eventKey="Events" title={t('Events')}>
          <ContainerEvents container={data} onAdd={addEvent} />
        </Tab>
      </Tabs>
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header>
          <Modal.Title>{t('DeleteContainer')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('DeleteContainerConfirm')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            {t('No')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowDelete(false)
              deleteContainer()
            }}
          >
            {t('Yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </ScreenPanel>
  ) : null
}

export default ContainerDetailsScreen

import React from 'react'
import Button from 'react-bootstrap/Button';
import { signInWithGoogle } from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SignInWithGoogle = () => {
    const history = useHistory();
    const { t } = useTranslation("auth");
    
    return (
        <Button onClick={ () => signInWithGoogle().then(result => history.replace("/")) }>
            {t("SignInWithGoogle")}
        </Button>
    )
}

export default SignInWithGoogle

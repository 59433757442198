import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './screen-sub-title.module.css';

const ScreenSubTitle = ({ label }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.screenSubTitle}>
            {t(label)}
        </div>
    )
}

export default ScreenSubTitle;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Workflows from "../../components/workflows/workflows.component";
import ScreenPanel from "../../components/screen-panel/screen-panel.component";
import { auth } from "../../firebase/firebase.utils";

import styles from './backend.module.css';

const BackendScreen = () => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("Backend") + " - D.S'FTS";
}, [t]);

  return (
    <ScreenPanel>
      <div className={styles.panel}>
        <div onClick={() => history.push("/events")} className={styles.panelButton}>{t("Events")}</div>
        <div onClick={() => history.push("/customers")} className={styles.panelButton}>{t("Customers")}</div>
        <div onClick={() => history.push("/orders")} className={styles.panelButton}>{t("Orders")}</div>
        <div onClick={() => history.push("/invoices")} className={styles.panelButton}>{t("Invoices")}</div>
        <div onClick={() => history.push("/containers")} className={styles.panelButton}>{t("Containers")}</div>
        <div onClick={() => history.push("/warehouses")} className={styles.panelButton}>{t("Warehouses")}</div>
        <div onClick={() => history.push("/inquiries")} className={styles.panelButton}>{t("Inquiries")}</div>
        <div onClick={() => history.push("/advertisements")} className={styles.panelButton}>{t("Advertisements")}</div>
        <div onClick={() => history.push("/users")} className={styles.panelButton}>{t("Users")}</div>
        <div onClick={() => history.push("/qr")} className={styles.panelButton}>{t("QRCodes")}</div>
        <a className={styles.panelButton} href="/app-release.apk">{t("DownloadApp")}</a>
        <div onClick={() => history.push("/status")} className={styles.panelButton}>{t("Status")}</div>
        {
          auth.currentUser && auth.currentUser.providerData[0].providerId === "password" &&
          <div onClick={() => history.push("/change-password")} className={styles.panelButton}>{t("Settings")}</div>
        }
      </div>
      <Workflows />
    </ScreenPanel>
  );
}

export default BackendScreen;

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ButtonDelete({ onDelete }) {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    return (
        <>
            <Button variant="secondary" onClick={() => setOpened(true)}>
                {t("Delete")}
            </Button>
            <Modal show={opened} onHide={() => setOpened(false)}>
                <Modal.Header>
                    <Modal.Title>{t("DeleteOrder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("DeleteOrderConfirm")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened(false)}>{t("No")}</Button>
                    <Button variant="primary" onClick={() => {
                        setOpened(false);
                        onDelete();
                    }}>{t("Yes")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GlobalState } from '../../backend/GlobalState';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import CustomerDisplayField from '../../components/customer-display-field/customer-display-field.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import KeyValueRow from "../../components/key-value-row/key-value-row.component";
import ErrorMessage from '../../backend/ErrorMessage';
import AuditInfo from '../../components/audit-info/audit-info.component';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const AdvertisementDetailsScreen = ({match}) => {
    const { advertisements } = useContext(Server);
    const globals = useContext(GlobalState);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const { t } = useTranslation();
    const history = useHistory();
    const id = match.params.advertisementId;

    useEffect(() => {
        advertisements.get(id, setData, setError);
    }, [advertisements, id]);

    useEffect(() => {
        document.title = t("Advertisements") + " - D.S'FTS";
    }, [t]);

    if (!data) return null;

    const edit = () => {
        history.push("/advertisements/" + id + "/edit");
    };

    const cancel = () => {
        history.push("/advertisements");
    };

    if (!data) return null;
    return (
        <ScreenPanel>
            <ErrorMessage error={error} />
            <ScreenTitle label='Advertisement' />
            <KeyValueRow k='Advertisementname'><CustomerDisplayField customer={data.customer} /></KeyValueRow>
            <KeyValueRow k='AdvertisementStart'>{data.start && fmtFR.format(new Date(data.start))}</KeyValueRow>
            <KeyValueRow k='AdvertisementEnd'>{data.end && fmtFR.format(new Date(data.end))}</KeyValueRow>
            <KeyValueRow k='AdvertisementPicture'>{data.picture}</KeyValueRow>
            <Image src={globals.basePath + "/images/" + data.picture} />
            <AuditInfo data={data} />
            <ButtonBar>
                <Button onClick={() => edit()}>{t('Edit')}</Button>
                <Button variant="secondary" onClick={() => cancel()}>{t('Cancel')}</Button>
            </ButtonBar>
        </ScreenPanel>
    );
}

export default AdvertisementDetailsScreen

import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import PasswordInput from '../../components/password-input/password-input.component';
import SignInWithGoogle from '../../components/signin-with-google/signin-with-google.component'
import StringInput from '../../components/string-input/string-input.component';
import ButtonBar from '../button-bar/button-bar.component';
import { auth } from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import styles from './signin.module.css';

const Signin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const history = useHistory();
    const { t } = useTranslation("auth");

    const signin = async () => {
        try {
            const { user } = await auth.signInWithEmailAndPassword(username, password)
            setError(null);
            history.replace("/");
        } catch (e) {
            setError(e.message);
        }
    }

    return (
        <div className={styles.signin}>
            <div className={styles.title}>{t("GotAccount")}</div>
            <div className={styles.subTitle}>{t("HintEmail")}</div>
            <StringInput label={t("Email")} value={username} onChange={setUsername} />
            <PasswordInput label={t("Password")} value={password} onChange={setPassword} />
            <ButtonBar>
                <Button onClick={signin}>{t("SignIn")}</Button>
                <SignInWithGoogle />
            </ButtonBar>
            {
                error &&
                <Alert variant="danger">{error}</Alert>
            }
        </div>
    )
}

export default Signin

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import SearchBox from '../../backend/SearchBox';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import Button from 'react-bootstrap/Button';
import ItemList from '../../components/item-list/item-list.component';
import CustomerDisplayField from '../../components/customer-display-field/customer-display-field.component';
import { Server } from '../../common/server.component';

const OrdersScreen = () => {
    const { orders } = useContext(Server);
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("Orders") + " - D.S'FTS";
    }, [t]);

    const search = query => {
        orders.query(query, setItems, setError);
    };

    const createOrder = () => {
        history.push("/orders/new/edit");
    };

    const selectInquiry = (id) => {
        history.push("/orders/" + id);
    };

    return (
        <ScreenPanel>
            <ScreenTitle label="Orders" />
            <SearchBox onQuery={search} >
                <Button variant="secondary" onClick={createOrder}>{t("CreateOrder")}</Button>
            </SearchBox>
            { error ? <Alert>{error}</Alert> : null}
            <ItemList>
                {
                    items && items.map(item => (
                        <ListGroup.Item key={item.id} onClick={() => selectInquiry(item.id)} style={{ cursor: 'pointer'}}>
                            <CustomerDisplayField customer={item.sender} /> {"->"} <CustomerDisplayField customer={item.recipient} /> [{item.itemCount}]: {item.message}
                        </ListGroup.Item>))
                }
            </ItemList>
        </ScreenPanel>
    )
}

export default OrdersScreen;

import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import ButtonBar from '../components/button-bar/button-bar.component';

export default function SearchBox(props) {
    const [query, setQuery] = useState("");
    const { t } = useTranslation();

    const handleKeyPress = evt => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            props.onQuery(query);
        }
    }

    return (
        <>
            <Form.Group>
                <Form.Control placeholder={t("SearchText")} value={query} onKeyPress={handleKeyPress} onChange={(evt) => {
                    if (props.onChangeText) {
                        props.onChangeText(evt.target.value);
                    }
                    setQuery(evt.target.value);
                }} />
            </Form.Group>
            <ButtonBar>
                <Button variant="primary" onClick={() => props.onQuery(query)}>
                    {t("Search")}
                </Button>
                {props.children}
            </ButtonBar>
        </>
    )
}

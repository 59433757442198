import React from 'react'
import Input from '../input/input.component';

const EmailInput = ({ label, value, onChange, onKeyPress, customClass }) => {
    return (
        <Input customClass={customClass} type="email" label={label} value={value} onChange={onChange} onKeyPress={onKeyPress} />
    )
}

export default EmailInput

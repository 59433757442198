import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import SearchBox from '../../backend/SearchBox';
import ItemList from '../item-list/item-list.component';
import De from '../de/de.component';
import Fr from '../fr/fr.component';
import En from '../en/en.component';

import { StatusContext } from './status-context.component';

import styles from './status-select.module.css';

const StatusSelect = ({ value, placeholder, onChangeText }) => {
    const { t } = useTranslation();
    const { allStatus, filterStatus, reload } = useContext(StatusContext);

    const [visible, setVisible] = useState(false);
    const [query, setQuery] = useState("");
    const [statusList, setStatusList] = useState();

    useEffect(() => {
        allStatus(setStatusList);
    }, [allStatus])

    useEffect(() => {
        filterStatus(query, setStatusList);
    }, [filterStatus, query])

    const text = value ?
        <span className={styles.value}><span>{value.statusId}</span> - <De>{value.de}</De><En>{value.em}</En><Fr>{value.fr}</Fr></span> :
        <span className={styles.placeholder}>{t(placeholder)}</span>
    return <>
        <div className={styles.statusSelect} onClick={() => setVisible(true)}>
            {text}
        </div>
        <Modal show={visible} onHide={() => setVisible(false)}>
            <Modal.Header>
                <Modal.Title>{t("SelectStatus")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchBox onChangeText={setQuery}>
                    <Button onClick={() => {
                        onChangeText({ statusId: query });
                        setVisible(false);
                        reload();
                    }}>{t("Add")}</Button>
                </SearchBox>
                <ItemList>
                    {
                        statusList ? statusList.map(
                            item => <ListGroup.Item className={styles.selectItem} onClick={() => {
                                onChangeText(item);
                                setVisible(false);
                            }} key={item.id}><span>{item.statusId}</span> - <De>{item.de}</De><En>{item.em}</En><Fr>{item.fr}</Fr></ListGroup.Item>
                        ) : null
                    }
                </ItemList>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
}

export default StatusSelect;

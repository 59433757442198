import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './payment-item-info.module.css'

const PaymentItemInfo = ({ payment, header }) => {
  const { t } = useTranslation()
  if (header) {
    return (
      <div className={styles.header}>
        <div className={styles.paymentItemInfo}>
          <span>{t('Date')}</span>
          <span>{t('Text')}</span>
          <span>{t('Amount')}</span>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.paymentItemInfo}>
      <span>{payment.date}</span>
      <span>{payment.text}</span>
      <span>{payment.amount}</span>
    </div>
  )
}

export default PaymentItemInfo

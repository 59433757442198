import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { auth } from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

var authStateHandler = null;

const AuthButton = () => {
    const [currentUser, setCurrentUser] = useState();
    const [roles, setRoles] = useState([]);
    const history = useHistory();
    const { t } = useTranslation("auth");

    useEffect(() => {
        authStateHandler = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        return () => {
            authStateHandler();
        };
    });

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then(result => {
                setRoles(result.claims.roles);
            });
        }
    }, [currentUser])

    const signOut = async () => {
        await auth.signOut();
        history.replace("/");
    }

    const popover = currentUser ? <Popover>
        <Popover.Title>{t("SignedInAs")}</Popover.Title>
        <Popover.Content>
            <div>{currentUser.displayName}</div>
            <div>{currentUser.email}</div>
            <div>{JSON.stringify(roles)}</div>
        </Popover.Content>
    </Popover> : null;

    return auth.currentUser ?
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="bottom-end" overlay={popover} >
            <Nav.Link onClick={signOut} style={{ cursor: "pointer" }}>{t("SignOut")}</Nav.Link>
        </OverlayTrigger> : <LinkContainer to="/signin">
            <Nav.Link>{t("SignIn")}</Nav.Link>
        </LinkContainer>
}

export default AuthButton;

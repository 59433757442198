import React, { useContext, useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';

import SearchBox from '../../backend/SearchBox';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import Button from 'react-bootstrap/Button';
import ItemList from '../../components/item-list/item-list.component';
import { Server } from '../../common/server.component';

const ContainersScreen = () => {
    const { containers } = useContext(Server);
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const { t } = useTranslation();

    const search = query => {
        containers.query(query, items => {
            setItems(items);
            setError(null);
        }, (err) => {
            setItems(null);
            setError(err.toString());
        });
    };

    const createItem = () => {
        history.push("/containers/new/edit");
    };

    const selectItem = (id) => {
        history.push("/containers/" + id);
    };

    return (
        <ScreenPanel>
            <ScreenTitle label="Containers" />
            <SearchBox onQuery={search} >
                <Button variant="secondary" onClick={createItem}>{t("CreateContainer")}</Button>
            </SearchBox>
            { error ? <Alert variant="danger">{error}</Alert> : null}
            <ItemList>
                {
                    items ? items.map(item => (
                        <ListGroup.Item key={item.id} onClick={() => selectItem(item.id)} style={{ cursor: 'pointer' }}>
                            {item.containerId} [{item.itemCount}]
                        </ListGroup.Item>)) : null
                }
            </ItemList>
        </ScreenPanel>
    )
}

export default ContainersScreen;

import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './input.module.css';

const Input = ({ label, placeholder, value, onChange, type, onKeyPress, customClass, validation }) => {
    const {t} = useTranslation();
    const l = value ? <div className={styles.label}>{t(label)} {validation?.required ? "*" : ""}</div> : <div className={styles.label}>{""}</div>;
    const regOptions = {};
    if (validation?.required) {
        regOptions.required = true;
    }
    return (
        <div className={styles.input + " " + customClass}>
            {l}
            <input
                type={type}
                name={validation?.name}
                ref={validation ? validation.register(regOptions) : null}
                value={validation ? undefined : value}
                defaultValue={validation ? value : undefined}
                onChange={validation ? null : ({ target: { value } }) => onChange(value)}
                onKeyPress={onKeyPress ? onKeyPress : () => { }}
                placeholder={(placeholder ? t(placeholder) : t(label)) + (validation?.required ? " *" : "")} />
            {validation?.errors[validation?.name] && <Alert variant="danger">{t(validation?.errors[validation?.name].type)}</Alert>}
        </div>
    )
}

export default Input

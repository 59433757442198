import React from 'react'

import styles from './status-info.module.css';

const StatusInfo = ({ status, header, onSelect }) => {
    if (header) {
        return <div className={styles.header}>
            <div className={styles.statusInfo}>
                <span>Status-ID</span>
                <span>Français</span>
                <span>Deutsch</span>
                <span>English</span>
            </div>
        </div>
    }
    return (
        <div className={styles.statusInfo} onClick={() => onSelect(status)}>
            <span>{status.statusId}</span>
            <span>{status.fr}</span>
            <span>{status.de}</span>
            <span>{status.en}</span>
        </div>
    )
}

export default StatusInfo

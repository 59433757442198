import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

import styles from './item-list.module.css';

const ItemList = ({ children }) => {
    return (
        <ListGroup className={styles.itemList}>
            {children}
        </ListGroup>
    )
}

export default ItemList;

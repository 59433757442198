import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ErrorMessage from '../../backend/ErrorMessage';
import ButtonBar from '../button-bar/button-bar.component';
import Modal from 'react-bootstrap/Modal';
import KeyValueRow from '../key-value-row/key-value-row.component';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Server } from '../../common/server.component';

const InquiryMessage = ({ data }) => {
    const { inquiries } = useContext(Server);
    const { t } = useTranslation();
    const [showDelete, setShowDelete] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();

    if (!data) return null;

    const deleteDo = () => {
        inquiries.delete(data.id, () => history.push("/inquiries"), setError);
    }

    const reply = () => {
        const url = "mailto:" + data.email + "?subject=Re: " + data.subject
        window.location.href = encodeURI(url); 
    };

    const cancel = () => {
        history.replace("/inquiries");
    };

    return (
        <>
            <ErrorMessage error={error} />
            <Container style={{ padding: 30 }}>
                <h3>Nachricht</h3>
                <KeyValueRow k="Name">{data.lastname}</KeyValueRow>
                <KeyValueRow k="Email">{data.email}</KeyValueRow>
                <KeyValueRow k="Subject">{data.lastname}</KeyValueRow>
                <KeyValueRow k="Message">{data.message}</KeyValueRow>
                <ButtonBar>
                    <Button variant="primary" onClick={() => reply()}>
                        {t("Reply")}
                    </Button>
                    <Button variant="secondary" onClick={() => cancel()}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowDelete(true)}>
                        {t("Delete")}
                    </Button>
                </ButtonBar>
            </Container>
            <Modal show={showDelete}>
                <Modal.Header>
                    <Modal.Title>{t("DeleteMessage")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("DeleteMessageConfirm")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDelete(false)}>{t("No")}</Button>
                    <Button variant="primary" onClick={() => deleteDo()}>{t("Yes")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InquiryMessage;

import React, { createContext } from 'react'
import { auth } from '../firebase/firebase.utils'

export const Server = createContext()

const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://www.dsfts.cf/api'

function executeGet(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executeGetBlobF(url, errorCallback, downloadName = 'qr.pdf') {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.blob()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject(text))
      })
      .then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = downloadName
        a.click()
      })
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type + '?q=' + query, callback, errorCallback)
  else executeGet('/' + type, callback, errorCallback)
}

function executePostF(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    fetch(_url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          response.json().then(result => callback(result))
          return
        }
        if (response.status === 204) {
          console.log('status 204')
          callback({})
          return
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          try {
            console.log('error with json')
            response
              .json()
              .then(error => errorCallback(error))
              .catch(error => errorCallback(error))
            return
          } catch (e) {
            console.log('+++++++++++++++++++++++++')
          }
        }
        console.log('error without json')
        response
          .text()
          .then(text => errorCallback({ message: text }))
          .catch(error => errorCallback(error))
      })
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executePutF(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function executeDelete(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(baseURL + url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return {}
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (errorCallback) errorCallback(error)
      })
  })
}

function getItemF(type, id, callback, errorCallback) {
  executeGet(`/${type}/${id}`, callback, errorCallback)
}

function deleteItem(type, id, callback, errorCallback) {
  executeDelete(`/${type}/${id}`, callback, errorCallback)
}

function createItemF(type, body, callback, errorCallback) {
  executePostF(`/${type}`, null, body, callback, errorCallback)
}

function updateItemF(type, id, body, callback, errorCallback) {
  executePutF(`/${type}/${id}`, body, callback, errorCallback)
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        advertisements: {
          query: (query, callback, errorCallback) => executeQuery('advertisements', query, callback, errorCallback),
          get: (adId, callback, errorCallback) => getItemF('advertisements', adId, callback, errorCallback),
          create: (body, callback, errorCallback) => createItemF('advertisements', body, callback, errorCallback),
          update: (adId, body, callback, errorCallback) => updateItemF('advertisements', adId, body, callback, errorCallback),
        },
        contacts: {
          load: (callback, errorCallback) => executeQuery('contacts', null, callback, errorCallback),
        },
        containers: {
          query: (query, callback, errorCallback) => executeQuery('containers', query, callback, errorCallback),
          get: (containerId, callback, errorCallback) => getItemF('containers', containerId, callback, errorCallback),
          create: (body, callback, errorCallback) => createItemF('containers', body, callback, errorCallback),
          update: (containerId, body, callback, errorCallback) => updateItemF('containers', containerId, body, callback, errorCallback),
          delete: (containerId, callback, errorCallback) => executeDelete(`/containers/${containerId}`, callback, errorCallback),
          addItem: (containerId, trackingId, callback, errorCallback) => executePostF(`/containers/${containerId}/items/${trackingId}`, null, null, callback, errorCallback),
          addEvent: (containerId, body, callback, errorCallback) => executePostF(`/containers/${containerId}/events`, null, body, callback, errorCallback),
        },
        customers: {
          query: (query, callback, errorCallback) => executeQuery('accounts', query, callback, errorCallback),
          get: (accountId, callback, errorCallback) => getItemF('accounts', accountId, callback, errorCallback),
          create: (body, callback, errorCallback) => createItemF('accounts', body, callback, errorCallback),
          update: (accountId, body, callback, errorCallback) => updateItemF('accounts', accountId, body, callback, errorCallback),
          advertisements: {
            get: (accountId, callback, errorCallback) => executeGet(`/accounts/${accountId}/advertisements`, callback, errorCallback),
          },
          orders: {
            get: (accountId, callback, errorCallback) => executeGet(`/accounts/${accountId}/orders`, callback, errorCallback),
            recipience: (accountId, callback, errorCallback) => executeGet(`/accounts/${accountId}/recipience`, callback, errorCallback),
          },
        },
        inquiries: {
          query: (query, callback, errorCallback) => executeQuery('inquiries', query, callback, errorCallback),
          get: (inquiryId, callback, errorCallback) => getItemF('inquiries', inquiryId, callback, errorCallback),
          delete: (inquiryId, callback, errorCallback) => executeDelete(`/inquiries/${inquiryId}`, callback, errorCallback),
          orders: {
            create: (body, callback, errorCallback) => executePostF(`/orders/inquiry`, body, callback, errorCallback),
          },
          customers: {
            get: (inquiryId, callback, errorCallback) => executeGet(`/inquiries/${inquiryId}/accounts`, callback, errorCallback),
          },
        },
        invoices: {
          query: (query, callback, errorCallback) => executeQuery('invoices', query, callback, errorCallback),
          get: (id, callback, errorCallback) => getItemF('invoices', id, callback, errorCallback),
          payments: {
            add: (id, body, callback, errorCallback) => createItemF(`invoices/${id}/payments`, body, callback, errorCallback),
          },
          sendMail: (id, callback, errorCallback) => executePostF(`/invoices/${id}/mail`, null, null, callback, errorCallback),
        },
        orders: {
          query: (query, callback, errorCallback) => executeQuery('orders', query, callback, errorCallback),
          get: (orderId, callback, errorCallback) => getItemF('orders', orderId, callback, errorCallback),
          create: (body, callback, errorCallback) => createItemF('orders', body, callback, errorCallback),
          update: (orderId, body, callback, errorCallback) => updateItemF('orders', orderId, body, callback, errorCallback),
          delete: (orderId, callback, errorCallback) => executeDelete(`/orders/${orderId}`, callback, errorCallback),
          events: {
            create: (orderId, body, callback, errorCallback) => executePostF(`/orders/${orderId}/events`, null, body, callback, errorCallback),
          },
          items: {
            create: (orderId, trackingId, callback, errorCallback) => executePostF(`/orders/${orderId}/items/${trackingId}`, null, null, callback, errorCallback),
            delete: (orderId, trackingId, callback, errorCallback) => executeDelete(`/orders/${orderId}/items/${trackingId}`, callback, errorCallback),
            events: {
              create: (orderId, trackingId, body, callback, errorCallback) => executePostF(`/orders/${orderId}/items/${trackingId}/events`, null, body, callback, errorCallback),
            },
          },
        },
        qr: {
          download: errorCallback => executeGetBlobF('/qr', errorCallback),
        },
        events: {
          add: (token, tracingId, body, callback, errorCallback) => executePostF(`/events/${tracingId}`, null, body, callback, errorCallback),
        },
        status_: {
          load: callback => executeQuery('status', null, callback, console.error),
          get: (id, callback) => getItemF('status', id, callback, console.error),
          create: (body, callback, errorCallback) => createItemF('status', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItemF('status', id, body, callback, errorCallback),
        },
        tours: {
          load: (callback, errorCallback) => executeQuery('tours', null, callback, errorCallback),
          create: (body, callback, errorCallback) => executePostF('/tours', null, body, callback, errorCallback),
          close: (tourId, callback, errorCallback) => executePostF(`/tours/${tourId}/close`, null, null, callback, errorCallback),
          addOrder: (tourId, body, callback, errorCallback) => executePostF(`/tours/${tourId}/orders`, null, body, callback, errorCallback),
          createDocuments: (tourId, errorCallback) => executeGetBlobF(`/tours/${tourId}/documents`, errorCallback, `${tourId}.pdf`),
        },
        users: {
          query: (callback, errorCallback) => executeQuery('users', null, callback, errorCallback),
          get: (userId, callback, errorCallback) => getItemF('users', userId, callback, errorCallback),
          delete: (userId, callback, errorCallback) => deleteItem('users', userId, callback, errorCallback),
          setRoles: (userId, body, callback, errorCallback) => executePostF(`/users/${userId}`, null, body, callback, errorCallback),
          init: (userId, realName, callback, errorCallback) => executePostF(`/users/${userId}/init`, null, { realName }, callback, errorCallback),
          password: (userId, password, callback, errorCallback) => executePostF(`/users/${userId}/password`, null, { password }, callback, errorCallback),
        },
        warehouses: {
          query: (query, callback, errorCallback) => executeQuery('warehouses', query, callback, errorCallback),
          get: (containerId, callback, errorCallback) => getItemF('warehouses', containerId, callback, errorCallback),
          create: (body, callback, errorCallback) => createItemF('warehouses', body, callback, errorCallback),
          update: (containerId, body, callback, errorCallback) => updateItemF('warehouses', containerId, body, callback, errorCallback),
          addItem: (containerId, trackingId, callback, errorCallback) => executePostF(`/warehouses/${containerId}/items/${trackingId}`, null, null, callback, errorCallback),
        },
      }}
    >
      {children}
    </Server.Provider>
  )
}

export default ServerProvider

import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ButtonCreateEvent({ onCreate }) {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [newStatus, setNewStatus] = useState("");
    const [newMessage, setNewMessage] = useState("");
    return (
        <>
            <Button variant="secondary" onClick={() => setOpened(true)}>
                {t("CreateEventForAll")}
            </Button>
            <Modal show={opened}>
                <Modal.Header>
                    <Modal.Title>{t("CreateEvent")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="eventState">
                        <Form.Control type="text" placeholder={t("NewStatus")} value={newStatus} onChange={(evt) => setNewStatus(evt.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="eventMessage">
                        <Form.Control type="text" placeholder={t("Message")} value={newMessage} onChange={(evt) => setNewMessage(evt.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened(false)}>{t("Cancel")}</Button>
                    <Button variant="primary" onClick={() => {
                        setOpened(false);
                        onCreate({
                            newStatus: newStatus,
                            message: newMessage
                        });
                    }}>{t("Ok")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

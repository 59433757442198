import React, { useContext, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import PasswordInput from '../../components/password-input/password-input.component';
import StringInput from '../../components/string-input/string-input.component';
import ButtonBar from '../button-bar/button-bar.component';
import { auth } from '../../firebase/firebase.utils';
import { useHistory } from "react-router-dom";
import { Server } from '../../common/server.component';
import { useTranslation } from 'react-i18next';

import styles from './signup.module.css';

const Signup = () => {
    const { users } = useContext(Server);
    const [displayName, setDisplayName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState();
    const history = useHistory();
    const { t } = useTranslation("auth");

    const register = async () => {
        if (password !== passwordConfirm) {
            setError(t("PasswordMismatch"));
            return;
        }
        try {
            const { user } = await auth.createUserWithEmailAndPassword(username, passwordConfirm);
            users.init(user.uid, displayName, () => { }, setError);
            setError(null);
            history.replace("/");
        } catch (e) {
            setError(e.message);
        }
    }

    return (
        <div className={styles.signup}>
            <div className={styles.title}>{t("NoAccount")}</div>
            <div className={styles.subTitle}>{t("HintNoEmail")}</div>
            <StringInput label={t("DisplayName")} value={displayName} onChange={setDisplayName} />
            <StringInput label={t("Email")} value={username} onChange={setUsername} />
            <PasswordInput label={t("Password")} value={password} onChange={setPassword} />
            <PasswordInput label={t("PasswordConfirm")} value={passwordConfirm} onChange={setPasswordConfirm} />
            <ButtonBar>
                <Button onClick={register}>{t("Register")}</Button>
            </ButtonBar>
            {
                error &&
                <Alert variant="danger">{error}</Alert>
            }
        </div>
    )
}

export default Signup

import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Server } from "../../common/server.component";
import ButtonBar from "../../components/button-bar/button-bar.component";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import ScreenPanel from "../../components/screen-panel/screen-panel.component";
import StringInput from "../../components/string-input/string-input.component";
import EmailInput from "../../components/email-input/email-input.component";

const CustomerEditScreen = ({ match: { params: { id } } }) => {
    const { customers } = useContext(Server);
    const [error, setError] = useState();
    const [customerId, setCustomerId] = useState();
    const [salutation, setSalutation] = useState("");
    const [lastname, setLastname] = useState("");
    const [firstname, setFirstname] = useState("");
    const [company, setCompany] = useState("");
    const [street, setStreet] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (id !== 'new') {
            customers.get(id, decompose, setError);
        }
    }, [customers, id]);

    const decompose = data => {
        setCustomerId(data.customerId);
        setSalutation(data.salutation);
        setLastname(data.lastname);
        setFirstname(data.firstname);
        setCompany(data.company);
        setStreet(data.street);
        setZip(data.zip);
        setCity(data.city);
        setCountry(data.country);
        setEmail(data.email);
        setPhone(data.phone);
    };

    const compose = () => {
        return {
            customerId,
            salutation,
            lastname,
            firstname,
            company,
            street,
            zip,
            city,
            country,
            email,
            phone
        }
    };

    const cancel = () => {
        if (id === "new") {
            history.replace("/customers");
        } else {
            history.replace("/customers/" + id);
        }
    };

    const sendMessage = () => {
        if (id === "new") {
            customers.create(compose(), data => {
                decompose(data);
                history.replace("/customers/" + data.id);
            }, setError);
        } else {
            customers.update(id, compose(), data => {
                decompose(data);
                history.replace("/customers/" + data.id);
            }, setError);
        }
    };

    const head = id === "new" ? t("CreateCustomer") : t("EditCustomer");
    return (
        <ScreenPanel>
            <h2>{head}</h2>
            <Form>
                <Form.Group controlId="formSalutation">
                    <Form.Check inline label={t("SalutationM")} type="radio" id="inline-1" value="M" checked={salutation === "M"} onChange={({ target: { value } }) => setSalutation(value)} />
                    <Form.Check inline label={t("SalutationF")} type="radio" id="inline-2" value="F" checked={salutation === "F"} onChange={({ target: { value } }) => setSalutation(value)} />
                    <Form.Check inline label={t("SalutationX")} type="radio" id="inline-3" value="X" checked={salutation === "X"} onChange={({ target: { value } }) => setSalutation(value)} />
                </Form.Group>
                <StringInput label={t("LastName")} value={lastname} onChange={setLastname} />
                <StringInput label={t("FirstName")} value={firstname} onChange={setFirstname} />
                <StringInput label={t("Company")} value={company} onChange={setCompany} />
                <StringInput label={t("Street")} value={street} onChange={setStreet} />
                <StringInput label={t("Zip")} value={zip} onChange={setZip} />
                <StringInput label={t("City")} value={city} onChange={setCity} />
                <StringInput label={t("Country")} value={country} onChange={setCountry} />
                <EmailInput label={t("Email")} value={email} onChange={setEmail} />
                <StringInput label={t("Phone")} value={phone} onChange={setPhone} />
                <ButtonBar>
                    <Button variant="primary" onClick={sendMessage}>
                        {t("Save")}
                    </Button>
                    <Button variant="secondary" onClick={cancel}>
                        {t("Cancel")}
                    </Button>
                </ButtonBar>
                {error && <Alert variant="danger">{error}</Alert>}
            </Form>
        </ScreenPanel>
    );
}

export default CustomerEditScreen

import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid'

export default function ButtonCreatePackage({ onCreate }) {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [trackingId, setTrackingId] = useState(uuidv4());
    return (
        <>
            <Button variant="primary" onClick={() => setOpened(true)}>
                {t("CreateOrderItem")}
            </Button>
            <Modal show={opened}>
                <Modal.Header>
                    <Modal.Title>{t("CreateOrderItem")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control type="text" placeholder={t("TrackingId")} value={trackingId} onChange={(evt) => setTrackingId(evt.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened(false)}>{t("Cancel")}</Button>
                    <Button variant="primary" onClick={() => {
                        setOpened(false);
                        onCreate({ trackingId: trackingId });
                    }}>{t("Ok")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

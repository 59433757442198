import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ListGroup from "react-bootstrap/ListGroup";
import { Server } from '../../common/server.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import SearchBox from '../../backend/SearchBox';

const InquiriesScreen = () => {
    const { inquiries } = useContext(Server);
    const history = useHistory();
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [response, setResponse] = useState();

    const handleClick = (q) => {
        inquiries.query(q, setResponse, setError);
    };

    const selectInquiry = (id) => {
        console.log("select inquiry", id);
        history.push("/inquiries/" + id);
    };

    document.title = t("Inquiries") + " - D.S.FTS";
    const res = response
        ? response.map((acc) => (
            <ListGroup.Item onClick={() => selectInquiry(acc.id)}>
                {acc.created} {acc.type}: {acc.lastname}, {acc.message}
            </ListGroup.Item>
        ))
        : null;
    return (
        <ScreenPanel>
            <h2>{t("Messages")} / {t("Inquiries")}</h2>
            <SearchBox onQuery={handleClick} />
            <ListGroup>
                {res}
            </ListGroup>
        </ScreenPanel>
    );
}

export default InquiriesScreen;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CustomerDisplayField from '../../components/customer-display-field/customer-display-field.component';
import KeyValueRow from '../../components/key-value-row/key-value-row.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import ScreenSubTitle from '../../components/screen-sub-title/screen-sub-title.component';
import ListGroup from 'react-bootstrap/ListGroup';
import ButtonCreatePackage from '../../backend/ButtonCreatePackage';
import ButtonCreateEvent from '../../backend/ButtonCreateEvent';
import Button from 'react-bootstrap/Button';
import ButtonCancel from '../../backend/ButtonCancel';
import ButtonDelete from '../../backend/ButtonDelete';
import OrderItem from '../../components/order-item/order-item.component';
import { Server } from '../../common/server.component';
import Alert from 'react-bootstrap/Alert';
import ButtonBar from '../../components/button-bar/button-bar.component';
import AuditInfo from '../../components/audit-info/audit-info.component';

const OrderDetailsScreen = ({ match }) => {
    const id = match.params.orderId;
    const { orders } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if (id !== "new") {
            orders.get(id, setData, setError);
        }
    }, [orders, id]);

    const load = () => {
        orders.get(id, setData, setError);
    };

    const doCreateOrderItem = data => {
        orders.items.create(id, data.trackingId, load, setError);
    };

    const doCreateEventForAllItems = data => {
        orders.events.create(id, data, load, setError);
    };

    const editOrder = () => {
        history.push("/orders/" + id + "/edit");
    };

    const cancel = () => {
        history.push("/orders");
    };

    const deleteDo = () => {
        orders.delete(id, () => history.push("/orders"), setError);
    }

    return data ? (
        <ScreenPanel>
            <ScreenTitle label="Order" />
            <KeyValueRow k="Sender"><CustomerDisplayField customer={data.sender} /></KeyValueRow>
            <KeyValueRow k="Recipient"><CustomerDisplayField customer={data.recipient} /></KeyValueRow>
            <KeyValueRow k="Message">{data.message}</KeyValueRow>
            <AuditInfo data={data} />

            <ScreenSubTitle label="OrderItems" />
            { data.items ? null : t("NoData")}
            <ListGroup>
                {data.items ? data.items.map(item => <OrderItem key={item.id} data={item} orderId={data.id}
                    onChange={load} onError={setError} />) : null}
            </ListGroup>
            <ButtonBar>
                <ButtonCreatePackage onCreate={doCreateOrderItem} />
                <ButtonCreateEvent onCreate={doCreateEventForAllItems} />
                <Button variant="secondary" onClick={editOrder}>{t("Edit")}</Button>
                <ButtonCancel onCancel={cancel} />
                <ButtonDelete onDelete={deleteDo} />
            </ButtonBar>
            {error && <Alert variant="danger">{error}</Alert>}
        </ScreenPanel>
    ) : null;
}

export default OrderDetailsScreen;

import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { auth } from '../../firebase/firebase.utils';
import { useTranslation } from 'react-i18next';

var authStateHandler = null;
var initializing = true;

const CheckAccess = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [roles, setRoles] = useState([]);
    const history = useHistory();
    const { t } = useTranslation("auth");

    const gotoSignIn = () => {
        history.replace("SignIn");
    }

    useEffect(() => {
        authStateHandler = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });
        initializing = false;
        return () => {
            authStateHandler();
        };
    });

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then(result => {
                setRoles(result.claims.roles);
            });
        }
    }, [currentUser])

    if (initializing) {
        return null;
    }

    if (currentUser && roles) {
        return children;
    }

    if (!currentUser) {
        return (
            <Container>
                <div>{t("MustBeSignedIn")}</div>
                <Button onClick={gotoSignIn}>{t("SignIn")}</Button>
            </Container>
        )
    }

    return (
        <Container>
            <div>{t("NoRole")}</div>
        </Container>
    )
}

export default CheckAccess

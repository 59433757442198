import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Server } from "../../common/server.component";
import ButtonBar from "../../components/button-bar/button-bar.component";
import ScreenPanel from "../../components/screen-panel/screen-panel.component";
import { Alert, Tab, Tabs } from "react-bootstrap";
import KeyValueRow from "../../components/key-value-row/key-value-row.component";
import OrderInfo from '../../components/order-info/order-info.component';
import CustomerDisplayField from "../../components/customer-display-field/customer-display-field.component";
import ErrorMessage from "../../backend/ErrorMessage";
import AuditInfo from "../../components/audit-info/audit-info.component";

const CustomerDetailsScreen = ({ match: { params: { id } } }) => {
    const { customers } = useContext(Server);
    const [data, setData] = useState();
    const [orders, setOrders] = useState([]);
    const [recipience, setRecipience] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const [error, setError] = useState();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        customers.get(id, setData, setError);
    }, [customers, id]);

    useEffect(() => {
        if (data) {
            customers.orders.get(data.customerId, setOrders, setError);
            customers.orders.recipience(data.customerId, setRecipience, setError);
            customers.advertisements.get(data.customerId, setAdvertisements, setError);
        }
    }, [customers, data]);

    if (!data) return null;

    const edit = () => {
        history.push("/customers/" + id + "/edit");
    };

    const cancel = () => {
        history.push("/customers");
    };

    return (
        <ScreenPanel>
            <ErrorMessage error={error} />
            <h2>{data.customerId} - {data.lastname}, {data.firstname}</h2>
            <Tabs>
                <Tab eventKey="Contact" title={t("Contact")}>
                    <KeyValueRow k="AccountID">
                        {data.customerId}
                    </KeyValueRow>
                    <KeyValueRow k="Company">
                        {data.company}
                    </KeyValueRow>
                    <KeyValueRow k="Salutation">
                        {data.salutation}
                    </KeyValueRow>
                    <KeyValueRow k="Name">
                        {data.lastname}, {data.firstname}
                    </KeyValueRow>
                    <KeyValueRow k="Address">
                        {data.street}<br />{data.zip} {data.city}<br />{data.country}
                    </KeyValueRow>
                    <KeyValueRow k="Coordinates">
                        <a target="_blank" href={`https://www.google.de/maps/search/${data.location?.lat},${data.location?.lng}`}>{data.location?.lat}, {data.location?.lng}</a>
                    </KeyValueRow>
                    <KeyValueRow k="Email">
                        {data.email}
                    </KeyValueRow>
                    <KeyValueRow k="Phone">
                        {data.phone}
                    </KeyValueRow>
                    <AuditInfo data={data} />
                    <ButtonBar>
                        <Button onClick={edit}>{t("Edit")}</Button>
                        <Button variant="secondary" onClick={cancel}>{t("Cancel")}</Button>
                    </ButtonBar>
                </Tab>
                <Tab eventKey="Orders" title={t("Orders")}>
                    {
                        orders.map(order => <OrderInfo orderId={order.id} onSelect={order => history.push(`/orders/${order.id}`)} />)
                    }
                    {
                        orders.length === 0 && <Alert variant="info">{t("NoData")}</Alert>
                    }
                </Tab>
                <Tab eventKey="Receptions" title={t("Receptions")}>
                    {
                        recipience.map(order => <OrderInfo orderId={order.id} onSelect={order => history.push(`/orders/${order.id}`)} />)
                    }
                    {
                        recipience.length === 0 && <Alert variant="info">{t("NoData")}</Alert>
                    }
                </Tab>
                <Tab eventKey="Advertisements" title={t("Advertisements")}>
                    {
                        advertisements.map(ad => <div key={ad.id} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer", margin: "5px 0" }} onClick={() => history.push(`/advertisements/${ad.id}`)}>
                            <CustomerDisplayField customer={ad.customer} /> <div>{ad.start}</div> <div>{ad.end}</div>
                        </div>)
                    }
                    {
                        advertisements.length === 0 && <Alert variant="info">{t("NoData")}</Alert>
                    }
                </Tab>
            </Tabs>
        </ScreenPanel>
    );
}

export default CustomerDetailsScreen

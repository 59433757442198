import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyAnR8IIjqckQ7MXsH1ah-DDjjuXV_a0IOM",
    authDomain: "dsfts-f99ac.firebaseapp.com",
    projectId: "dsfts-f99ac",
    storageBucket: "dsfts-f99ac.appspot.com",
    messagingSenderId: "98592078836",
    appId: "1:98592078836:web:e6e3a4be521d5c192c57a1"
  };

  firebase.initializeApp(config);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();

  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account"});
  export const signInWithGoogle = () => auth.signInWithPopup(provider);

  export default firebase;

import React, { useContext, useEffect, useState } from 'react'
import { Server } from '../../common/server.component'
import Contact from '../../components/contact/contact.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'

const ContactClientsScreen = () => {
    const { contacts } = useContext(Server);
    const [items, setItems] = useState();

    useEffect(() => {
        contacts.load(setItems, console.error);
    }, [contacts])

    return (
        <ScreenPanel>
            <ScreenTitle label="ContactClients" />
            <Contact header />
            {items && items.map(t => <Contact key={t.t1.id} customer={t.t1} count={t.t2} />)}
        </ScreenPanel>
    )
}

export default ContactClientsScreen

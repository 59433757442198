import React, { useState, useContext } from 'react';
import { Button, Dropdown, Form, ListGroup, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Server } from '../../common/server.component';
import { StatusContext } from '../status-select/status-context.component';
import Status from '../status/status.component';

export default function OrderItem({ data, orderId, onChange, onError }) {
    const { t } = useTranslation();
    const { orders } = useContext(Server);

    const [opened, setOpened] = useState(false);
    const [addEventModal, setAddEventModal] = useState(false);
    const [deleteItemModal, setDeleteItemModal] = useState(false);
    const [newStatus, setNewStatus] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const events = data.events ? data.events : [];
    const addEvent = () => {
        setAddEventModal(false);
        orders.items.events.create(orderId, data.id, {
            message: newMessage,
            newStatus: newStatus
        }, onChange, onError);
    };
    const deleteItem = () => {
        orders.items.delete(orderId, data.id, onChange, onError);
        setDeleteItemModal(false);
    };
    const evts = opened ? <ListGroup>
        {events.map(evt => <ListGroup.Item key={evt.created}>{evt.created}: <Status statusId={evt.status} /> - {evt.message}</ListGroup.Item>)}
    </ListGroup> : null;
    return (
        <>
            <ListGroup.Item>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <span style={{ flex: 9 }} onClick={() => setOpened(!opened)}>
                        {data.trackingId}: <Status statusId={data.status} /> {data.typeOfGoods} {data.value} {opened ? null : "[" + events.length + "]"}
                    </span>
                    <Dropdown style={{ flex: 1 }}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">...</Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onSelect={() => setAddEventModal(true)}>Neues Ereignis</Dropdown.Item>
                            <Dropdown.Item onSelect={() => setDeleteItemModal(true)}>Löschen</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {evts}
            </ListGroup.Item>
            <Modal show={addEventModal} onHide={() => setAddEventModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t("CreateEvent")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="eventState">
                        <Form.Control type="text" placeholder={t("NewStatus")} value={newStatus} onChange={(evt) => setNewStatus(evt.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="eventMessage">
                        <Form.Control type="text" placeholder={t("Message")} value={newMessage} onChange={(evt) => setNewMessage(evt.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAddEventModal(false)}>{t("Cancel")}</Button>
                    <Button variant="primary" onClick={() => addEvent()}>{t("Add")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteItemModal} onHide={() => setDeleteItemModal(false)}>
                <Modal.Header>
                    <Modal.Title>{t("DeleteOrderItem")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("DeleteOrderItemConfirm")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteItemModal(false)}>{t("No")}</Button>
                    <Button variant="primary" onClick={() => deleteItem()}>{t("Yes")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

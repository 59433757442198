import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../backend/ErrorMessage';
import { Server } from '../../common/server.component';
import InquiryMessage from '../../components/inquiry-message/inquiry-message.component';
import InquiryOrder from '../../components/inquiry-order/inquiry-order.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';

const InquiryDetailsScreen = ({ match: { params: { inquiryId } } }) => {
    const { inquiries } = useContext(Server);
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        inquiries.get(inquiryId, setData, setError);
    }, [inquiries, inquiryId]);

    document.title = t("Inquiry") + " - D.S.FTS";
    if (!data) return null;
    return <ScreenPanel>
        <ErrorMessage error={error} />
        {data.type === "message" ?
            <InquiryMessage data={data} /> :
            <InquiryOrder data={data} />}
    </ScreenPanel>;

}

export default InquiryDetailsScreen

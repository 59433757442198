import React from 'react';
import Input from '../input/input.component';

const StringInput = ({ label, value, onChange, onKeyPress, customClass, validation }) => {
    return (
        <Input customClass={customClass} type="text" label={label} value={value} onChange={onChange} onKeyPress={onKeyPress} validation={validation}/>
    )
}

export default StringInput;

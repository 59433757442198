import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import CustomerEditField from '../../components/customer-edit-field/customer-edit-field.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { useTranslation } from 'react-i18next';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import StringInput from '../../components/string-input/string-input.component';
import ErrorMessage from '../../backend/ErrorMessage';

const OrderEditScreen = ({ match }) => {
    const id = match.params.orderId;
    const { orders } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState({});
    const [error, setError] = useState();

    useEffect(() => {
        if (id !== "new") {
            orders.get(id, setData, setError);
        }
    }, [orders, id]);

    const saveOrder = () => {
        if (id === "new") {
            orders.create(data, response => {
                console.log("===< order", response);
                history.push("/orders/" + response.id);
            }, setError);
        } else {
            orders.update(id, data, response => {
                console.log("===< order", response);
                history.push("/orders/" + response.id);
            });
        }
    };

    const cancel = () => {
        if (id !== "new") history.push("/orders/" + id);
        else history.push("/orders");
    };

    return (
        <ScreenPanel>
            <ErrorMessage error={error} />
            <ScreenTitle label={id === "new" ? "CreateOrder" : "EditOrder"} />
            <CustomerEditField customer={data.sender} onChange={customer => setData({ ...data, sender: customer })} />
            <CustomerEditField customer={data.recipient} onChange={customer => setData({ ...data, recipient: customer })} />
            <StringInput label={t("Message")} value={data.message} onChange={evt => setData({ ...data, message: evt })} />
            <ButtonBar>
                <Button variant="primary" onClick={saveOrder}>
                    {t("Save")}
                </Button>
                <Button variant="secondary" onClick={cancel}>
                    {t("Cancel")}
                </Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default OrderEditScreen;

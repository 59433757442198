import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { useTranslation } from 'react-i18next';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import StringInput from '../../components/string-input/string-input.component';
import { notify } from 'react-notify-toast';

const ContainerEditScreen = ({ match }) => {
    const id = match.params.containerId;
    const { containers } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState({});

    useEffect(() => {
        if (id !== "new") {
            containers.get(id, setData);
        }
    }, [containers, id]);

    const saveContainer = () => {
        if (id === "new") {
            containers.create(data, response => {
                history.push("/containers/" + response.id);
                notify.show(t('Created'), 'success', 3000);
            }, console.error);
        } else {
            containers.update(id, data, response => {
                history.push("/containers/" + response.id);
                notify.show(t('Updated'), 'success', 3000);
            }, console.error);
        }
    };

    const cancel = () => {
        history.push(id === "new" ? "/containers" : "/containers/" + id);
    };

    return (
        <ScreenPanel>
            <ScreenTitle label={id === "new" ? "CreateContainer" : "EditContainer"} />
            <StringInput label={t("ContainerId")} value={data.containerId} onChange={evt => setData({ ...data, containerId: evt })} />
            <ButtonBar>
                <Button variant="primary" onClick={saveContainer}>
                    {t("Save")}
                </Button>
                <Button variant="secondary" onClick={cancel}>
                    {t("Cancel")}
                </Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default ContainerEditScreen;

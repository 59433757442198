import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Server } from '../../common/server.component'
import AuditInfo from '../../components/audit-info/audit-info.component'
import ButtonBar from '../../components/button-bar/button-bar.component'
import InvoiceItemInfo from '../../components/invoice-item-info/invoice-item-info.component'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import PaymentItemInfo from '../../components/payment-item-info/payment-item-info.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenSubTitle from '../../components/screen-sub-title/screen-sub-title.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'

const InvoiceDetailsScreen = ({
  match: {
    params: { id },
  },
}) => {
  const [data, setData] = useState()
  const [opened, setOpened] = useState(false)
  const [text, setText] = useState('')
  const [date, setDate] = useState('')
  const [amount, setAmount] = useState('')
  const { invoices } = useContext(Server)
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      invoices.get(id, setData, console.error)
    }
  }, [id])

  const onAdd = () => {
    invoices.payments.add(
      id,
      {
        date,
        text,
        amount,
      },
      () => invoices.get(id, setData, console.error),
      console.error
    )
  }

  const sendMail = () => {
    invoices.sendMail(id, setData, console.error)
  }

  return data ? (
    <ScreenPanel>
      <ScreenTitle label="Invoice" />
      <KeyValueRow k="InvoiceId">{data.id}</KeyValueRow>
      <KeyValueRow k="AccountID">{data.customerId}</KeyValueRow>
      <KeyValueRow k="Company">{data.company}</KeyValueRow>
      <KeyValueRow k="Salutation">{data.salutation}</KeyValueRow>
      <KeyValueRow k="Name">
        {data.lastname}, {data.firstname}
      </KeyValueRow>
      <KeyValueRow k="Address">
        {data.street}
        <br />
        {data.zip} {data.city}
        <br />
        {data.country}
      </KeyValueRow>
      <KeyValueRow k="Email">{data.email}</KeyValueRow>
      <KeyValueRow k="Phone">{data.phone}</KeyValueRow>
      <KeyValueRow k="Total">{data.total}</KeyValueRow>
      <KeyValueRow k="ToPay">{data.toPay}</KeyValueRow>
      <AuditInfo data={data} />
      <ScreenSubTitle label="InvoiceItems" />
      <InvoiceItemInfo header />
      {data.items && data.items.map((item, idx) => <InvoiceItemInfo key={idx} item={item} />)}
      <ScreenSubTitle label="Payments" />
      <PaymentItemInfo header />
      {data.payments && data.payments.map((payment, idx) => <PaymentItemInfo key={idx} payment={payment} />)}
      <div>
        {data.status === 'NEW' ? <div>{t('EmailNotSent')}</div> : null}
        {data.status === 'ERROR' ? (
          <div>
            {t('EmailError')}: {data.mailSentDate}
          </div>
        ) : null}
        {data.status === 'SENT' ? (
          <div>
            {t('EmailSent')}: {data.mailSentDate}
          </div>
        ) : null}
      </div>
      <ButtonBar>
        <Button disabled={data.toPay <= 0} onClick={() => setOpened(true)}>
          {t('PaymentAdd')}
        </Button>
        <Button disabled={!data.email} onClick={sendMail}>
          {t('SendMail')}
        </Button>
      </ButtonBar>
      <Modal show={opened}>
        <Modal.Header>
          <Modal.Title>{t('PaymentAdd')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="date">
            <Form.Control type="text" placeholder={t('Date')} value={date} onChange={({ target }) => setDate(target.value)} />
          </Form.Group>
          <Form.Group controlId="text">
            <Form.Control type="text" placeholder={t('Text')} value={text} onChange={({ target }) => setText(target.value)} />
          </Form.Group>
          <Form.Group controlId="amount">
            <Form.Control type="text" placeholder={t('Amount')} value={amount} onChange={({ target }) => setAmount(target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpened(false)}>
            {t('Cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setOpened(false)
              onAdd()
            }}
          >
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </ScreenPanel>
  ) : null
}

export default InvoiceDetailsScreen

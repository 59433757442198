import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import styles from './invoice-info.module.css'

const InvoiceInfo = ({ invoice, header }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const select = () => {
    history.push(`/invoices/${invoice.id}`)
  }

  if (header) {
    return (
      <div className={styles.header}>
        <div className={styles.invoiceInfo}>
          <span>{t('AccountIDAbrev')}</span>
          <span>{t('Name')}</span>
          <span>{t('Total')}</span>
          <span>{t('ToPay')}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.invoiceInfo} onClick={select}>
      <span>{invoice.customerId}</span>
      <span>
        {invoice.lastname}, {invoice.firstname}
      </span>
      <span>{invoice.total}</span>
      <span>{invoice.toPay}</span>
    </div>
  )
}

export default InvoiceInfo

import React, { useContext, useState } from 'react'
import SearchBox from '../../backend/SearchBox'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../common/server.component'
import InvoiceInfo from '../../components/invoice-info/invoice-info.component'

const InvoicesScreen = () => {
  const { invoices } = useContext(Server)
  const [items, setItems] = useState()

  const search = q => {
    invoices.query(q, setItems, console.error)
  }

  return (
    <ScreenPanel>
      <ScreenTitle label="Invoices" />
      <SearchBox onQuery={search} />
      <InvoiceInfo header />
      {items && items.map(item => <InvoiceInfo key={item.id} invoice={item} />)}
    </ScreenPanel>
  )
}

export default InvoicesScreen

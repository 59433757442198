import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { useTranslation } from 'react-i18next';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import StringInput from '../../components/string-input/string-input.component';

const WarehouseEditScreen = ({ match }) => {
    const id = match.params.warehouseId;
    const { warehouses } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState({});

    useEffect(() => {
        if (id !== "new") {
            warehouses.get(id, res => {
                setData(res);
            });
        }
    }, [warehouses, id]);

    const saveWarehouse = () => {
        if (id === "new") {
            warehouses.create(data, response => {
                history.push("/warehouses/" + response.id);
            }, () => { });
        } else {
            warehouses.update(id, data, response => {
                history.push("/warehouses/" + response.id);
            }, () => { });
        }
    };

    const cancel = () => {
        if (id !== "new") history.push("/warehouses/" + id);
        else history.push("/warehouses");
    };

    return (
        <ScreenPanel>
            <ScreenTitle label={id === "new" ? "CreateWarehouse" : "EditWarehouse"} />
            <StringInput label={t("WarehouseId")} value={data.warehouseId} onChange={evt => setData({ ...data, warehouseId: evt })} />
            <ButtonBar>
                <Button variant="primary" onClick={saveWarehouse}>
                    {t("Save")}
                </Button>
                <Button variant="secondary" onClick={cancel}>
                    {t("Cancel")}
                </Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default WarehouseEditScreen;

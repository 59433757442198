import React, { useContext, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CustomerDisplayField from '../customer-display-field/customer-display-field.component'
import { Server } from '../../common/server.component'
import SearchBox from '../../backend/SearchBox'
import StringInput from '../string-input/string-input.component'
import EmailInput from '../email-input/email-input.component'

import styles from './customer-edit-field.module.css'
import ButtonBar from '../button-bar/button-bar.component'

const CustomerEditField = ({ customClass, customer, onChange, label }) => {
  const { t } = useTranslation()
  const { customers } = useContext(Server)
  const [show, setShow] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [customerList, setCustomerList] = useState([])
  const [message, setMessage] = useState()
  const [error, setError] = useState()

  const [salutation, setSalutation] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [company, setCompany] = useState('')
  const [street, setStreet] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseEdit = () => setShowEdit(false)

  const selectCustomer = customer => {
    onChange(customer)
    setShow(false)
  }
  const handleClick = query => {
    customers.query(query, setCustomerList, setError)
  }
  const createCustomer = () => {
    handleClose()
    setShowEdit(true)
  }
  const compose = () => {
    return {
      salutation,
      lastname,
      firstname,
      company,
      street,
      zip,
      city,
      country,
      email,
      phone,
    }
  }

  const clear = () => {
    setSalutation('')
    setLastname('')
    setFirstname('')
    setCompany('')
    setStreet('')
    setZip('')
    setCity('')
    setCountry('')
    setEmail('')
    setPhone('')
  }

  const saveAndSelectCustomer = () => {
    customers.create(
      compose(),
      data => {
        onChange(data)
        clear()
      },
      setError
    )
    setShowEdit(false)
  }

  var top = null
  var id = null
  if (customer) {
    top = label ? t(label) : t('NoCustomer')
    var name = customer.lastname
    if (customer.firstname) {
      name = name ? name + ', ' + customer.firstname : ''
    }
    id = customer?.customerId
    if (id) {
      if (name) {
        id += ' - ' + name
      }
    } else {
      id = name
    }
  } else {
    top = ' '
    id = label ? t(label) : t('NoCustomer')
  }
  const custs = customerList.map(acc => (
    <ListGroup.Item key={acc.id} onClick={() => selectCustomer(acc)}>
      <CustomerDisplayField customer={acc} />
    </ListGroup.Item>
  ))
  return (
    <>
      <div className={styles.customerEdit + ' ' + customClass} onClick={() => handleShow()}>
        <div className={styles.label}>{top}</div>
        <div className={styles.value}>{id}</div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('SelectCustomer')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{message}</span>
          <Form>
            <SearchBox onQuery={handleClick}>
              <Button variant="secondary" onClick={createCustomer}>
                {t('NewCustomer')}
              </Button>
            </SearchBox>
          </Form>
          <ListGroup>{custs}</ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('NewCustomer')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{message}</span>
          <Form>
            <Form.Group controlId="formSalutation">
              <Form.Check inline label={t('SalutationM')} type="radio" id="inline-1" value="M" checked={salutation === 'M'} onChange={({ target: { value } }) => setSalutation(value)} />
              <Form.Check inline label={t('SalutationF')} type="radio" id="inline-2" value="F" checked={salutation === 'F'} onChange={({ target: { value } }) => setSalutation(value)} />
              <Form.Check inline label={t('SalutationX')} type="radio" id="inline-3" value="X" checked={salutation === 'X'} onChange={({ target: { value } }) => setSalutation(value)} />
            </Form.Group>
            <StringInput label={t('LastName')} value={lastname} onChange={setLastname} />
            <StringInput label={t('FirstName')} value={firstname} onChange={setFirstname} />
            <StringInput label={t('Company')} value={company} onChange={setCompany} />
            <StringInput label={t('Street')} value={street} onChange={setStreet} />
            <StringInput label={t('Zip')} value={zip} onChange={setZip} />
            <StringInput label={t('City')} value={city} onChange={setCity} />
            <StringInput label={t('Country')} value={country} onChange={setCountry} />
            <EmailInput label={t('Email')} value={email} onChange={setEmail} />
            <StringInput label={t('Phone')} value={phone} onChange={setPhone} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBar>
            <Button variant="primary" onClick={saveAndSelectCustomer}>
              {t('Save')}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleCloseEdit()
                handleShow()
              }}
            >
              {t('Cancel')}
            </Button>
          </ButtonBar>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomerEditField

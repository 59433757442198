import React, { createContext, useContext, useState } from 'react';
import { Server } from '../../common/server.component';

export const StatusContext = createContext();

const StatusProvider = ({ children }) => {
    const { status_ } = useContext(Server);
    const [status, setStatus] = useState();
    const [loaded, setLoaded] = useState(false);
    const checkLoad = (callback) => {
        if (!loaded) {
            status_.load(data => {
                setLoaded(true);
                setStatus(data);
                if (status) {
                    callback(data);
                }
            });
        } else {
            if (status) {
                callback(status);
            }
        }
    };
    return (
        <StatusContext.Provider value={{
            allStatus: (setter) => {
                checkLoad(status => setter(status));
            },
            getStatus: (statusId, setter) => {
                checkLoad(status => {
                    const x = status.find(s => s.statusId === statusId);
                    setter(x ? x : { statusId: statusId });
                });
            },
            filterStatus: (query, setter) => {
                checkLoad(status => setter(status.filter(s => {
                    return s.statusId.toLowerCase().includes(query.toLowerCase());
                })));
            },
            reload: () => setLoaded(false)
        }}>
            {children}
        </StatusContext.Provider>
    )
}

export default StatusProvider;

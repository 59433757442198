import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ButtonBar from '../button-bar/button-bar.component';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import styles from './workflow.module.css';

const Workflow = ({ children, title, wfid, startable }) => {
    const [opened, setOpened] = useState(false)
    const history = useHistory();
    const { t } = useTranslation();

    const startWorkflow = () => {
        switch (wfid) {
            case 'plan':
                history.push("/tour/planning");
                return;
            case 'contactClients':
                history.push("/contactClients");
                return;
            case 'ContainerOnShip':
                history.push("/containers");
                return;
            case 'ContainerArrived':
                history.push("/containers");
                return;
        }
    }

    return (
        <div className={styles.workflow}>
            <div className={styles.title} onClick={() => setOpened(!opened)}>
                <span className={styles.title}>{t(title)}</span>
                <span className={styles.toggle}>{opened ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
            </div>
            { opened && <div className={styles.body}>
                {children}
                {startable && <ButtonBar>
                    <Button onClick={startWorkflow}>{t("Start")}</Button>
                </ButtonBar>}
            </div>}
        </div>
    )
}

export default Workflow;

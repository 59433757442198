import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

import styles from './audit-info.module.css';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const AuditInfo = ({ data }) => {
    const { t } = useTranslation();

    const popover = <div className={styles.auditPopover}>
        <Popover.Title>{t("AuditInfo")}</Popover.Title>
        <Popover.Content>
            <div className={styles.row}>
                <span>{t("Created")}</span>
                <span>{data.created && fmtFR.format(new Date(data.created))}</span>
                <span>{t("by")}</span>
                <span>{data.createdBy}</span>
            </div>
            <div className={styles.row}>
                <span>{t("Modified")}</span>
                <span>{data.modified && fmtFR.format(new Date(data.modified))}</span>
                <span>{t("by")}</span>
                <span>{data.modifiedBy}</span>
            </div>
        </Popover.Content>
    </div>;

    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="bottom-end" overlay={popover} >
            <div className={styles.auditInfo}>
                <span>{t("Modified")}</span>
                <span>{data.modified && fmtFR.format(new Date(data.modified))}</span>
            </div>
        </OverlayTrigger>
    )
}

export default AuditInfo

import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Server } from '../../common/server.component'
import ButtonBar from '../../components/button-bar/button-bar.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import StringInput from '../../components/string-input/string-input.component'
import { useForm } from "react-hook-form";
import { notify } from 'react-notify-toast';
import AuditInfo from '../../components/audit-info/audit-info.component'

const StatusDetailsScreen = ({ match: { params: { id } } }) => {
    const { register, handleSubmit, watch, errors } = useForm();
    const { status_ } = useContext(Server);
    const { t } = useTranslation();
    const history = useHistory()
    const [data, setData] = useState();

    useEffect(() => {
        if (id !== 'new') {
            status_.get(id, setData);
        } else {
            setData({});
        }
    }, [status_, id])

    if (!data) {
        return null;
    }

    const valOps = {
        register,
        watch,
        errors
    };

    const save = data => {
        console.log("save", data);
        if (id !== 'new') {
            status_.update(id, data, d => {
                setData(d);
                notify.show(t('Saved'), 'success', 3000);
                history.replace("/status");
            }, console.error);
        } else {
            status_.create(data, d => {
                setData(d);
                notify.show(t('Created'), 'success', 3000);
                history.replace("/status");
            }, console.error);
        }
    }

    return (
        <ScreenPanel>
            <ScreenTitle label="Status Details" />
            <form onSubmit={(handleSubmit(save))}>
                <StringInput label={t("StatusId")} value={data.statusId} validation={{ ...valOps, name: "statusId", required: true }} />
                <StringInput label="Français" value={data.fr} validation={{ ...valOps, name: "fr" }} />
                <StringInput label="Deutsch" value={data.de} validation={{ ...valOps, name: "de" }} />
                <StringInput label="English" value={data.en} validation={{ ...valOps, name: "en" }} />
                <AuditInfo data={data} />
                <ButtonBar>
                    <Button variant="secondary" onClick={() => history.goBack()}>{t("Cancel")}</Button>
                    <Button type="submit">{t("Save")}</Button>
                </ButtonBar>
            </form>
        </ScreenPanel>
    )
}

export default StatusDetailsScreen

import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { Server } from '../../common/server.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';

const CustomersScreen = () => {
    const { customers } = useContext(Server);
    const history = useHistory();
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [response, setResponse] = useState();
    const [q, setQ] = useState("");
    const [message, setMessage] = useState();

    document.title = t("Customers") + " - D.S.FTS";
    if (error) {
        return <ScreenPanel style={{ padding: 30 }}>{error}</ScreenPanel>;
    }
    const res = response
        ? response.map((acc) => (
            <ListGroup.Item onClick={() => selectCustomer(acc.id)}>
                {acc.customerId} - {acc.lastname}, {acc.firstname} { acc.company ? "(" + acc.company + ")" : ""}
            </ListGroup.Item>
        ))
        : null;

    const selectCustomer = (id) => {
        history.push("/customers/" + id);
    };

    const createCustomer = () => {
        history.push("/customers/new/edit");
    };

    const handleClick = () => {
        customers.query(q, setResponse, setError);
    };

    return (
        <ScreenPanel>
            <h2>{t("Customers")}</h2>
            <Form>
                <Form.Group controlId="formText">
                    <Form.Control placeholder={t("SearchText")} value={q} onChange={({ target: { value } }) => setQ(value)} />
                </Form.Group>
                <Button variant="primary" onClick={handleClick}>
                    {t("Search")}
                </Button>{" "}
                <Button variant="secondary" onClick={() => createCustomer()}>
                    {t("NewCustomer")}
                </Button>
            </Form>
            <div>{message ? t(message) : null}</div>
            <ListGroup>
                {res}
            </ListGroup>
        </ScreenPanel>
    );
}

export default CustomersScreen;

import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonBar from '../../components/button-bar/button-bar.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import StatusSelect from '../../components/status-select/status-select.component';
import StringInput from '../../components/string-input/string-input.component';
import { useTranslation } from 'react-i18next';
import { Server } from '../../common/server.component';
import { GlobalState } from '../../backend/GlobalState';
import { notify } from 'react-notify-toast';
import Error from '../../components/error/error.component';

const EventsScreen = () => {
    const [status, setStatus] = useState();
    const [message, setMessage] = useState('');
    const [trackingId, setTrackingId] = useState('');
    const [error, setError] = useState();
    const { token } = useContext(GlobalState);
    const { events } = useContext(Server);
    const { t } = useTranslation();

    const addEvent = () => {
        events.add(token, trackingId, {
            message,
            newStatus: status.statusId,
        }, () => {
            setTrackingId('');
            notify.show(t('Added'), 'success', 3000);
        }, setError);
    }

    return (
        <ScreenPanel>
            <ScreenTitle label="CreateEvent" />
            <StatusSelect value={status} placeholder={t("Status")} onChangeText={setStatus} />
            <StringInput value={message} label={t("Message")} onChange={setMessage} />
            <StringInput value={trackingId} label={t("TrackingId")} onChange={setTrackingId} />
            <Error error={error} />
            <ButtonBar>
                <Button disabled={!status || !trackingId} onClick={addEvent} >{t("Add")}</Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default EventsScreen;

import React, { useContext, useEffect, useState } from 'react'
import { StatusContext } from '../status-select/status-context.component';
import i18next from 'i18next';

const Status = ({statusId}) => {
    const { getStatus } = useContext(StatusContext);
    const [status, setStatus] = useState();
    const [label, setLabel] = useState()

    useEffect(() => {
        getStatus(statusId, setStatus);
    }, [statusId, getStatus])

    console.log("##### Status", status);

    useEffect(() => {
        if (status) {
            if (i18next.language === 'de') {
                setLabel(status.de ? status.de : "DEX" + statusId)
            }
            if (i18next.language === 'fr') {
                setLabel(status.fr ? status.fr : "FRX" + statusId)
            }
            if (i18next.language === 'en') {
                setLabel(status.en ? status.en : "ENX" + statusId)
            }
        }
    }, [status])

    return (
        <span>{label}</span>
    )
}

export default Status

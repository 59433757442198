import React, { useContext, useEffect, useState } from 'react'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../common/server.component';
import StatusInfo from '../../components/status-info/status-info.component';
import { useHistory } from 'react-router';
import ButtonBar from '../../components/button-bar/button-bar.component';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const StatusScreen = () => {
    const { status_ } = useContext(Server);
    const [statusItems, setStatusItems] = useState([]);
    const history = useHistory();
    const {t} = useTranslation();

    useEffect(() => {
        status_.load(setStatusItems);
    }, [status_]);

    const selectStatus = item => {
        history.push(`/status/${item.id}`);
    }

    const createItem = () => {
        history.push("/status/new");
    }

    return (
        <ScreenPanel>
            <ScreenTitle label="Status" />
            <StatusInfo header />
            {
                statusItems.map(item => <StatusInfo status={item} onSelect={selectStatus} />)
            }
            <ButtonBar>
                <Button onClick={createItem}>{t("Create")}</Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default StatusScreen

import React, { useContext, useState } from 'react'
import { Button, Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Server } from '../../common/server.component';
import CustomerDisplayField from '../../components/customer-display-field/customer-display-field.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const AdvertisementsScreen = () => {
    const { advertisements } = useContext(Server);
    const { t } = useTranslation();
    const [response, setResponse] = useState();
    const [q, setQ] = useState("");
    const [error, setError] = useState();
    const history = useHistory();

    const handleClick = () => {
        advertisements.query(q, setResponse, setError);
    };

    document.title = t("Advertisements") + " - D.S.FTS";
    if (error) {
        return <ScreenPanel>{error}</ScreenPanel>;
    }
    const res = response
        ? response.map(acc => {
            return <ListGroup.Item key={acc.id} onClick={() => selectItem(acc.id)}>
                <CustomerDisplayField customer={acc.customer} />: {acc.start && fmtFR.format(new Date(acc.start))} - {acc.end && fmtFR.format(new Date(acc.end))}
            </ListGroup.Item>
        })
        : null;

    const selectItem = (id) => {
        console.log("select item", id);
        history.push("/advertisements/" + id);
    };

    const create = () => {
        history.push("/advertisements/new/edit");
    };

    const qChanged = (evt) => {
       setQ(evt.target.value);
    };

    return (
        <ScreenPanel>
            <ScreenTitle label="Advertisements"/>
            <Form.Group controlId="formText">
                <Form.Control placeholder={t('PlaceholderAdvertisementname')} value={q} onChange={(evt) => qChanged(evt)} />
            </Form.Group>
            <Button variant="primary" onClick={handleClick}>
                {t('Search')}
            </Button>{" "}
            <Button variant="secondary" onClick={() => create()}>
                {t('CreateAdvertisement')}
            </Button>
            <ListGroup>
                {res}
            </ListGroup>
        </ScreenPanel>
    );
}

export default AdvertisementsScreen

import React, { useContext, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Server } from '../../common/server.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import CustomerEditField from '../../components/customer-edit-field/customer-edit-field.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import ImageSelector from '../../components/image-selector/ImageSelector';
import { notify } from 'react-notify-toast';

const AdvertisementEditScreen = ({ match }) => {
    const id = match.params.advertisementId;
    const head = id === "new" ? 'CreateAdvertisement' : 'EditAdvertisement';

    const { advertisements } = useContext(Server);
    const [data, setData] = useState({});
    const [error, setError] = useState();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        advertisements.get(id, setData, setError);
    }, [advertisements, id]);

    const cancel = () => {
        if (id === "new") {
            history.push("/advertisements");
        } else {
            history.push("/advertisements/" + id);
        }
    };

    const sendMessage = () => {
        if (id === 'new') {
            advertisements.create(data, r => {
                history.push(`/advertisements/${r.id}`);
                notify.show(t('Created'), 'success', 3000);
            }, setError);
        } else {
            advertisements.update(id, data, r => {
                history.push(`/advertisements/${r.id}`);
                notify.show(t('Updated'), 'success', 3000);
            }, setError);
        }
    };

    return (
        <ScreenPanel>
            <ScreenTitle label={head} />
            <Form>
                <Form.Group controlId="formAdvertisementname">
                    <CustomerEditField label={t("Customer")}  customer={data.customer} onChange={customer => setData({ ...data, customer: customer })} />
                </Form.Group>
                <Form.Group controlId="formBasicStart">
                    <Form.Control type="text" placeholder={t('PlaceholderAdvertisementStart')} value={data.start} onChange={evt => setData({ ...data, start: evt.target.value })} />
                </Form.Group>
                <Form.Group controlId="formBasicEnd">
                    <Form.Control type="text" placeholder={t('PlaceholderAdvertisementEnd')} value={data.end} onChange={evt => setData({ ...data, end: evt.target.value })} />
                </Form.Group>
                <Form.Group controlId="formBasicImage">
                    <ImageSelector value={data.picture} onChange={picture => setData({ ...data, picture })} />
                </Form.Group>
                <ButtonBar>
                    <Button variant="primary" onClick={() => sendMessage()}>
                        {t('Save')}
                    </Button>
                    <Button variant="secondary" onClick={() => cancel()}>
                        {t('Cancel')}
                    </Button>
                </ButtonBar>
            </Form>
        </ScreenPanel>);
}

export default AdvertisementEditScreen

import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import ButtonBar from '../../components/button-bar/button-bar.component';
import PasswordInput from '../../components/password-input/password-input.component';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from "react-router-dom";
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import { Server } from '../../common/server.component';
import { auth } from '../../firebase/firebase.utils';

const ChangePasswordScreen = () => {
    const {users} = useContext(Server);
    const [passwordNew, setPasswordNew] = useState('');
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <ScreenPanel>
            <h2>{t('Settings')}</h2>
            <h3>{t('ChangePassword')}</h3>
            <PasswordInput label={t('PasswordNew')} value={passwordNew} onChange={setPasswordNew} />
            { message ? <Alert variant="success">{message}</Alert> : null }
            { error ? <Alert variant="danger">{JSON.stringify(error)}</Alert> : null }
            <ButtonBar>
                <Button variant="primary" size="lg" onClick={() => {
                    users.password(auth.currentUser.uid, passwordNew, () => {
                        setMessage(t('PasswordChanged'));
                        setTimeout(() => history.push('/'), 3000);
                    }, setError);
                }}>{t('Apply')}</Button>
                <LinkContainer to='/'>
                    <Button variant="secondary" size="lg">{t('Cancel')}</Button>
                </LinkContainer>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default ChangePasswordScreen;

import React from 'react'
import { useTranslation } from 'react-i18next';

import styles from './contact.module.css'

const Contact = ({ customer, count, header }) => {
    const { t } = useTranslation();
    if (header) {
        return <div className={styles.header} >
            <div className={styles.info}>
                <span>{t('Recipient')}</span>
                <span>{t('Name')}</span>
                <span>{t('Phone')}</span>
                <span>{t('Count')}</span>
            </div>
        </div>;
    }
    return (
        <div className={styles.info}>
            <span>{customer.customerId}</span>
            <span>{customer.lastname}, {customer.firstname}</span>
            <span>{customer.phone}</span>
            <span>{count}</span>
        </div>
    )
}

export default Contact

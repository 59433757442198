import React, { useContext, useEffect, useState } from 'react'
import { Server } from '../../common/server.component'
import CustomerDisplayField from '../customer-display-field/customer-display-field.component';

import styles from './order-info.module.css';

const OrderInfo = ({ orderId, setError, onSelect }) => {
    const { orders } = useContext(Server);
    const [order, setOrder] = useState();

    useEffect(() => {
        orders.get(orderId, setOrder, setError);
    }, [orders, orderId, setError])

    const select = () => {
        if (onSelect) {
            onSelect(order);
        }
    }

    return (order ?
        <div className={[styles.orderInfo, onSelect ? styles.selectable : ""].join(' ')} onClick={select}>
            <span>{order.created.substring(0, 10)}</span>
            <CustomerDisplayField customer={order.sender} />
            <CustomerDisplayField customer={order.recipient} />
            <span>{order.itemCount}</span>
        </div> : null
    )
}

export default OrderInfo

import React from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { GlobalState } from "../../backend/GlobalState";
import styles from "./ImageSelector.module.css";
import { withTranslation } from "react-i18next";

class ImageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedFile: null
        }
    }

    static contextType = GlobalState;

    componentDidMount() {
        fetch(this.context.basePath + "/images")
            .then((response) => {
                if (response.status === 200) return response.json();
                else return { status: response.status, message: response.statusText };
            })
            .then((json) =>
                this.setState({
                    response: json,
                    error: null,
                })
            )
            .catch((err) => {
                this.setState({ error: err.toString() });
            });
    }

    render() {
        const { t } = this.props;
        const images = this.state.response ? this.state.response.map(image => <div key={image.name} className={styles.selectable} onClick={_ => this.selectImage(image)}>
            <div>{image.name}</div>
            <Image src={this.context.basePath + "/images/" + image.name} style={{ width: 200 }} />
        </div>) : null;
        return (<>
            <div className={styles.edit} onClick={() => this.setState({ show: true })}>
                <div>{this.props.value}</div>
                <Image src={this.context.basePath + "/images/" + this.props.value} />
            </div>
            <Modal show={this.state.show}>
                <Modal.Header>
                    <Modal.Title>{t("SelectAddPicture")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <input type="file" name="file" onChange={this.onFileChange} />
                        <Button variant="primary" onClick={this.onFileUpload}>
                        {t("Add")}
                        </Button>
                    </div>
                    {images}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={_ => this.setState({ show: false })}>{t("Cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>);
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    onFileUpload = file => {
        const formData = new FormData();
        formData.append("file", this.state.selectedFile);
        console.log(this.state.selectedFile);

        fetch(this.context.basePath + "/images", {
            method: "POST",
            body: formData
        })
    };

    selectImage = (image) => {
        this.props.onChange(image.name);
        this.setState({ show: false });
    }
}

export default withTranslation()(ImageSelector);
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonBar from '../button-bar/button-bar.component';
import { useTranslation } from 'react-i18next';
import ItemList from '../item-list/item-list.component';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const ContainerItems = ({ container, onAdd }) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [trackingId, setTrackingId] = useState('');

    return <>
        <div>
            {
                container.trackingIds ? (<ItemList>
                    { container.trackingIds.map(id => <ListGroup.Item key={id} style={{ cursor: 'pointer' }}>
                        {id}</ListGroup.Item>)}
                </ItemList>) : <div>{t("NoData")}</div>
            }
            <ButtonBar>
                <Button variant="primary" onClick={() => setOpened(true)}>{t("Add")}</Button>
            </ButtonBar>
        </div>
        <Modal show={opened}>
            <Modal.Header>
                <Modal.Title>{t("AddPaket")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="trackingId">
                    <Form.Control type="text" placeholder={t("TrackingId")} value={trackingId} onChange={({ target }) => setTrackingId(target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpened(false)}>{t("Cancel")}</Button>
                <Button variant="primary" onClick={() => {
                    setOpened(false);
                    onAdd(trackingId);
                }}>{t("Ok")}</Button>
            </Modal.Footer>
        </Modal>
    </>;
}

export default ContainerItems;

import React, { Component } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Navbar, Nav, Image, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import i18n from './i18n';
import i18next from 'i18next';
import { GlobalState } from "./backend/GlobalState";
import ServerProvider from "./common/server.component";
import ChangePasswordScreen from "./screens/change-password/change-password.screen";
import OrdersScreen from "./screens/orders/orders.screen";
import OrderEditScreen from "./screens/order-edit/order-edit.screen";
import OrderDetailsScreen from "./screens/order-details/order-details.screen";
import InvoicesScreen from "./screens/invoices/invoices.screen";

import ContainersScreen from "./screens/containers/containers.screen";
import ContainerDetailsScreen from "./screens/container-details/container-details.screen";
import ContainerEditScreen from "./screens/container-edit/container-edit.screen";
import StatusProvider from "./components/status-select/status-context.component";
import WarehousesScreen from "./screens/warehouses/warehouses.screen";
import WarehouseDetailsScreen from "./screens/warehouse-details/warehouse-details.screen";
import WarehouseEditScreen from "./screens/warehouse-edit/warehouse-edit.screen";
import SignInScreen from "./screens/signin/signin.screen";

import AuthButton from "./components/auth-button/auth-button.component";
import CustomersScreen from "./screens/customers/customers.screen";
import CustomerDetailsScreen from "./screens/customer-details/customer-details.screen";
import CustomerEditScreen from "./screens/customer-edit/customer-edit.screen";
import QrScreen from "./screens/qr/qr.screen";
import InquiriesScreen from "./screens/inquiries/inquiries.screen";
import InquiryDetailsScreen from "./screens/inquiry-details/inquiry-details.screen";
import UsersScreen from "./screens/users/users.screen";

import AdvertisementsScreen from "./screens/advertisements/advertisements.screen";
import AdvertisementDetailsScreen from "./screens/advertisement-details/advertisement-details.screen";
import AdvertisementEditScreen from "./screens/advertisement-edit/advertisement-edit.screen";
import TourPlanningScreen from "./screens/tour-planning/tour-planning.screen";
import EventsScreen from "./screens/events/events.screen";
import BackendScreen from "./screens/backend/backend.screen";

import Notifications from 'react-notify-toast';
import StatusScreen from "./screens/status/status.screen";
import StatusDetailsScreen from "./screens/status-details/status-details.screen";
import ContactClientsScreen from "./screens/contact-clients/contact-clients.screen";
import UserDetailsScreen from "./screens/user-details/user-details.screen";

import "./App.css";
import InvoiceDetailsScreen from "./screens/invoice-details/invoice-details.screen";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basePath: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "https://www.dsfts.cf/api",
      authenticated: false,
      token: null,
      setToken: (token) => this.setState({ authenticated: true, token: token }),

      changePassword: (body, callback) => this._protectedPost("password", body, callback),
    };
  }

  _protectedPost(type, request, callback) {
    const opts = this.authorizationHeader();
    opts.method = "POST";
    opts.body = JSON.stringify(request);
    opts.headers["Content-Type"] = "application/json";
    fetch(this.state.basePath + "/" + type, opts)
      .then((response) => {
        if (response.status === 200) response.json().then(json => callback({ error: null, data: json }));
        else callback({
          error: {
            status: response.status,
            message: response.statusText
          }
        });
      })
      .catch((err) => {
        callback({
          error: {
            message: err.toString()
          }
        });
      });
  }

  authorizationHeader() {
    return {
      headers: {
        Authorization: "Bearer " + this.state.token,
        Accept: "application/json",
      },
    };
  }

  componentDidMount() { }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    return true;
  }

  render() {
    const { t, i18n } = this.props;
    const n = (
      <Nav className="mr-auto">
        <LinkContainer to="/customers">
          <Nav.Link>{t("Customers")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/orders">
          <Nav.Link>{t("Orders")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/inquiries">
          <Nav.Link>{t("Inquiries")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/users">
          <Nav.Link>{t("Users")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/advertisements">
          <Nav.Link>{t("Advertisements")}</Nav.Link>
        </LinkContainer>
        <AuthButton />
      </Nav>
    );

    return (
      <GlobalState.Provider value={this.state}>
        <ServerProvider>
          <StatusProvider>
            <Router>
              <Navbar bg="custom" expand="lg" sticky="top">
                <LinkContainer to="/">
                  <Navbar.Brand>
                    <Image src="/assets/Logo-name.png" style={{ height: 50, margin: -13 }} />
                  </Navbar.Brand>
                </LinkContainer>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <NavDropdown title={i18next.language} id="language-dropdown">
                    <Nav.Link href="#" onClick={() => this.changeLanguage('de')}>de</Nav.Link>
                    <Nav.Link href="#" onClick={() => this.changeLanguage('fr')}>fr</Nav.Link>
                    <Nav.Link href="#" onClick={() => this.changeLanguage('en')}>en</Nav.Link>
                  </NavDropdown>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">{n}</Navbar.Collapse>
              </Navbar>
              <Notifications options={{zIndex: 10000, top: '50px'}}/>
              
              <div>
                <Switch>
                  <Route exact path="/advertisements" component={AdvertisementsScreen} />
                  <Route exact path="/advertisements/:advertisementId" component={AdvertisementDetailsScreen} />
                  <Route exact path="/advertisements/:advertisementId/edit" component={AdvertisementEditScreen} />

                  <Route exact path="/inquiries" component={InquiriesScreen} />
                  <Route exact path="/inquiries/:inquiryId" component={InquiryDetailsScreen} />

                  <Route exact path="/customers" component={CustomersScreen} />
                  <Route exact path="/customers/:id" component={CustomerDetailsScreen} />
                  <Route exact path="/customers/:id/edit" component={CustomerEditScreen} />

                  <Route exact path="/orders" component={OrdersScreen} />
                  <Route exact path="/orders/:orderId/edit" component={OrderEditScreen} />
                  <Route exact path="/orders/:orderId" component={OrderDetailsScreen} />

                  <Route exact path="/invoices" component={InvoicesScreen} />
                  <Route exact path="/invoices/:id" component={InvoiceDetailsScreen} />

                  <Route exact path="/containers" component={ContainersScreen} />
                  <Route exact path="/containers/:containerId" component={ContainerDetailsScreen} />
                  <Route exact path="/containers/:containerId/edit" component={ContainerEditScreen} />

                  <Route exact path="/warehouses" component={WarehousesScreen} />
                  <Route exact path="/warehouses/:warehouseId" component={WarehouseDetailsScreen} />
                  <Route exact path="/warehouses/:warehouseId/edit" component={WarehouseEditScreen} />

                  <Route exact path="/status" component={StatusScreen} />
                  <Route exact path="/status/:id" component={StatusDetailsScreen} />

                  <Route exact path="/qr" component={QrScreen} />

                  <Route exact path="/users" component={UsersScreen} />
                  <Route exact path="/users/:id" component={UserDetailsScreen} />

                  <Route exact path="/signin" component={SignInScreen} />
                  <Route exact path="/change-password" component={ChangePasswordScreen} />

                  <Route exact path="/tour/planning" component={TourPlanningScreen} />

                  <Route exact path="/" component={BackendScreen} />
                  <Route exact path="/events" component={EventsScreen}/>

                  <Route exact path="/contactClients" component={ContactClientsScreen} />
                </Switch>
              </div>
            </Router>
          </StatusProvider>
        </ServerProvider>
      </GlobalState.Provider>
    );
  }
}

export default withTranslation()(App);

import React from 'react'
import Workflow from '../../components/workflow/workflow.component'
import De from '../de/de.component'
import En from '../en/en.component'
import Fr from '../fr/fr.component'
import ScreenTitle from '../screen-title/screen-title.component'

const Workflows = () => {
  return (
    <div>
      <ScreenTitle label="Workflows" />
      <Workflow title="TourPlanning" wfid="plan" startable>
        <De>
          <>
            <div>Hier kannst du deine Touren vorbereiten. Dazu gehört:</div>
            <ul>
              <li>neue Kunden anlegen,</li>
              <li>Aufträge anlegen,</li>
              <li>Aufträge ausdrucken,</li>
              <li>QR-Codes erstellen</li>
              <li>Liste der anzufahrenden Kunden bzw. Adressen erstellen.</li>
            </ul>
          </>
        </De>
        <En>
          <>
            <div>Here you can prepare your tours. This includes:</div>
            <ul>
              <li>create new customers,</li>
              <li>Create orders,</li>
              <li>Print orders,</li>
              <li>Create QR codes</li>
              <li>Create a list of customers or addresses to be approached.</li>
            </ul>
          </>
        </En>
        <Fr>
          <>
            <div>Ici, vous pouvez préparer vos visites. Ceci comprend:</div>
            <ul>
              <li>créer de nouveaux clients,</li>
              <li>créer des commandes,</li>
              <li>imprimer des commandes,</li>
              <li>créer des codes QR</li>
              <li>créez une liste de clients ou d'adresses à visiter.</li>
            </ul>
          </>
        </Fr>
      </Workflow>
      <Workflow title="OnTour" wfid="onTour">
        <De>
          <>
            <div>Mit den Dokumenten aus dem Workflow "Tour-Planung" fährst du von Kunde zu Kunde und machst dort folgendes:</div>
            <ul>
              <li>In der App öffnest du den Auftrag. (Klicke "Auf der Tour" und scanne den Barcode auf der Rechnung)</li>
              <li>Für jeden Karton nimmst du einen Aufkleber-Streifen</li>
              <ul>
                <li>du klebst einen Aufkleber auf die Rechnung</li>
                <li>die restlichen Aufkleber klebst du auf den Karton</li>
              </ul>
              <li>In der App fügst du alle Kartons durch scannen des Codes zum Auftrag hinzu (Klicke "Pakete hinzufügen")</li>
              <li>Nun machst du die Rechnung fertig: Klicke auf das Plus und wähle Rechnung erzeugen.</li>
              <li>Bearbeite die Rechnung, klicke dann auf Rechnung versenden.</li>
              <li>Lade die Kartons in den Wagen.</li>
            </ul>
          </>
        </De>
        <En>
          <>
            <div>With the documents from the "Plan a Tour" workflow, you drive from customer to customer and do the following there: </div>
            <ul>
              <li>You open the order in the app. (Click "On Tour" and scan the barcode on the invoice) </li>
              <li>Use a sticker strip for each box </li>
              <ul>
                <li>you put a sticker on the bill </li>
                <li>Stick the remaining stickers onto the box </li>
              </ul>
              <li>In the app you add all the boxes to the order by scanning the code (click "Add packages") </li>
              <li> Now you finish the invoice: Click on the plus and select "Create invoice". </li>
              <li> Process the invoice, then click on "Send invoice". </li>
              <li> Load the packages into the cart. </li>
            </ul>
          </>
        </En>
        <Fr>
          <>
            <div>Avec les documents du workflow "Planifier une tournée", vous passez d'un client à l'autre et procédez comme suit: </div>
            <ul>
              <li>Vous ouvrez la commande dans l'application. (Cliquez sur "En tournée" et scannez le code-barres sur la facture) </li>
              <li>Utilisez une bande d'autocollants pour chaque boîte </li>
              <ul>
                <li>Collez un autocollant sur la facture </li>
                <li>Collez les autres autocollants sur la boîte </li>
              </ul>
              <li>Dans l'application, vous ajoutez toutes les boîtes à la commande en scannant le code (cliquez sur "Ajouter des colis") </li>
              <li> Maintenant que vous avez terminé la facture: cliquez sur le signe plus et sélectionnez "Créer une facture". </li>
              <li> Traitez la facture, puis cliquez sur "Envoyer la facture". </li>
              <li> Chargez les boîtes dans le panier. </li>
            </ul>
          </>
        </Fr>
      </Workflow>
      <Workflow title="EndOfTour" wfid="endOfTour">
        <De>
          <>
            <div>Alle Pakete werden nun aus dem Fahrzeug entladen und in das Lager eingelaget.</div>
            <ul>
              <li>Klicke in der App auf "Ende der Tour"</li>
              <li>Drücke den Plus-Schalter</li>
              <li>Für jeden Karton scanne den Barcode</li>
            </ul>
          </>
        </De>
        <En>
          <>
            <div>All packages are now unloaded from the vehicle and placed in the warehouse.</div>
            <ul>
              <li>Click on "End of tour" in the app</li>
              <li>Press the plus button</li>
              <li>For each box, scan the barcode</li>
            </ul>
          </>
        </En>
        <Fr>
          <>
            <div>Tous les colis sont maintenant déchargés du véhicule et placés dans l'entrepôt.</div>
            <ul>
              <li>Cliquez sur "Fin de la tournée" dans l'application</li>
              <li>Appuyez sur le bouton plus</li>
              <li>Pour chaque carton, scannez le code-barres</li>
            </ul>
          </>
        </Fr>
      </Workflow>
      <Workflow title="ContainerLoad" wfid="">
        <De>
          <>
            <div>Ein Container wird beladen. Alle Pakete, die in den Container geladen werden, müssen gescannt werden.</div>
            <ul>
              <li>Klicke in der App auf "Container beladen"</li>
              <li>Lege einen neuen Container an oder wähle einen Container aus.</li>
              <li>Für jeden Karton scanne den Barcode. Die Kartons werden zum Container hinzugefügt.</li>
            </ul>
          </>
        </De>
        <En>
          <>
            <div> A container is being loaded. All packages that are loaded into the container must be scanned. </div>
            <ul>
              <li> Click on "Load Container" in the app </li>
              <li> Create a new container or select a container. </li>
              <li> Scan the barcode for each package. The packages are added to the container.</li>
            </ul>
          </>
        </En>
        <Fr>
          <>
            <div> Un conteneur est en cours de chargement. Tous les packages chargés dans le conteneur doivent être scannes. </div>
            <ul>
              <li> Cliquez sur "Chargement de conteneur" dans l'application </li>
              <li> Créez un nouveau conteneur ou sélectionnez un conteneur. </li>
              <li> Scannez le code-barres de chaque colis. Les colis sont ajoutées au conteneur.</li>
            </ul>
          </>
        </Fr>
      </Workflow>
      <Workflow title="ContainerOnShip" wfid="ContainerOnShip" startable>
        <De>
          <div>Der Container wurde auf ein Schiff verladen. Um die Kunden darüber zu informieren, führe folgendes aus:</div>
          <ul>
            <li>Suche den Container</li>
            <li>Öffne die Seite Ereignisse</li>
            <li>Drücke den Schalter zufügen</li>
            <li>Wähle den Status "LOADED" aus</li>
            <li>Gib optional eine zusätzliche Nachricht ein</li>
            <li>Füge das Ereigniss hinzu</li>
          </ul>
        </De>
        <En>
          <ul>
            <li>Find the container</li>
            <li>Open the Events page</li>
            <li>Hit the add button</li>
            <li>Select the status "LOADED"</li>
            <li>Optionally, enter an additional message</li>
            <li>Add the event</li>
          </ul>
        </En>
        <Fr>
          <ul>
            <li>Trouvez le conteneur</li>
            <li>Ouvrez la page Evénements</li>
            <li>Appuyez sur le bouton d'ajout</li>
            <li>Sélectionnez le statut "LOADED"</li>
            <li>Si vous le souhaitez, entrez un message supplémentaire</li>
            <li>Ajouter l'événement</li>
          </ul>
        </Fr>
      </Workflow>
      <Workflow title="ContainerArrived" wfid="ContainerArrived" startable>
        <De>
          <div>
            <ul>
              <li>Suche den Container</li>
              <li>Öffne die Seite Ereignisse</li>
              <li>Drücke den Schalter zufügen</li>
              <li>Wähle den Status "LANDED" aus</li>
              <li>Gib optional eine zusätzliche Nachricht ein</li>
              <li>Füge das Ereigniss hinzu</li>
            </ul>
          </div>
        </De>
        <En>
          <div>
            <ul>
              <li>Find the container</li>
              <li>Open the Events page</li>
              <li>Hit the add button</li>
              <li>Select the status "LANDED"</li>
              <li>Optionally, enter an additional message</li>
              <li>Add the event</li>
            </ul>
          </div>
        </En>
        <Fr>
          <div>
            <ul>
              <li>Trouvez le conteneur</li>
              <li>Ouvrez la page Evénements</li>
              <li>Appuyez sur le bouton d'ajout</li>
              <li>Sélectionnez le statut "LANDED"</li>
              <li>Si vous le souhaitez, entrez un message supplémentaire</li>
              <li>Ajouter l'événement</li>
            </ul>
          </div>
        </Fr>
      </Workflow>
      <Workflow title="ContainerUnload" wfid="">
        <De>
          <div>Der Container wird geöffnet und alle Pakete aus dem Container werden in das Lager 'Abidjan' eingelagert.</div>
          <ul>
            <li>Klicke in der App auf 'Container entladen' und wählen Sie das Lager 'Abidjan'.</li>
            <li>Für jeden Karton: scanne den Barcode</li>
          </ul>
        </De>
        <En>
          <div> The container is opened and all packages from the container are stored in the 'Abidjan' warehouse. </div>
          <ul>
            <li> Click on 'Unload Container' in the app and select the 'Abidjan' warehouse. </li>
            <li> For each box: scan the barcode </li>
          </ul>
        </En>
        <Fr>
          <div> Le conteneur est ouvert et tous les colis du conteneur sont stockés dans l'entrepôt 'Abidjan'. </div>
          <ul>
            <li> Cliquez sur "Déchargement de conteneur" dans l'application et sélectionnez l'entrepôt 'Abidjan'. </li>
            <li> Pour chaque colis: scannez le code-barres </li>
          </ul>
        </Fr>
      </Workflow>
      <Workflow title="ContactClients" wfid="contactClients" startable>
        <De>
          <div>Starte den Workflow. Die Seite 'Kunden kontaktieren' wird geöffnet. Die Listet alle Kunden, die Pakete im Lager haben mit Telefonnummer.</div>
        </De>
        <En>
          <div>Start the workflow. The 'Contact Customers' page opens. The lists all customers who have parcels in the warehouse with their telephone number.</div>
        </En>
        <Fr>
          <div>Démarrez le workflow. La page 'Contacter les clients' s'ouvre. Le répertorie tous les clients qui ont des colis dans l'entrepôt avec leur numéro de téléphone.</div>
        </Fr>
      </Workflow>
      <Workflow title="CollectionByClient" wfid="">
        <De>
          <ul>
            <li>Öffne in der App 'Abholung durch Kunden'. </li>
            <li>Suche den Empfänger.</li>
            <li>Es wird die Liste der vorhanden Pakete für diesen Kunden angezeigt.</li>
            <li>Klicke auf 'Scannen'.</li>
            <li>Hole die Pakete aus dem Lager und scanne deren Barcode.</li>
            <li>Klicke am Ende auf 'Fertig'.</li>
            <li>Prüfe nochmal die Daten auf der folgenden Seite.</li>
            <li>Klicke dann 'Übergeben'.</li>
          </ul>
        </De>
        <En>
          <ul>
            <li> Open 'Pick up by customer' in the app. </li>
            <li> Find the recipient. </li>
            <li> The list of available packages for this customer is displayed. </li>
            <li> Click on 'Scan'. </li>
            <li> Get the parcels from the warehouse and scan their barcode. </li>
            <li> Click on 'Done' at the end. </li>
            <li> Check the data on the following page again. </li>
            <li> Then click 'Hand over'. </li>
          </ul>
        </En>
        <Fr>
          <ul>
            <li> Ouvrez 'Ramassage par client' dans l'application. </li>
            <li> Recherchez le destinataire. </li>
            <li> La liste des packages disponibles pour ce client s'affiche. </li>
            <li> Cliquez sur 'Scannez'. </li>
            <li> Récupérez les colis de l'entrepôt et scannez leur code-barres. </li>
            <li> Cliquez sur "Fini" à la fin. </li>
            <li> Vérifiez à nouveau les données sur la page suivante. </li>
            <li> Cliquez ensuite sur 'Remettre'. </li>
          </ul>
        </Fr>
      </Workflow>
      <Workflow title="PrepareDelivery" wfid="">
        <De>
          <ul>
            <li>Öffne in der App 'Laden für Auslieferung'.</li>
            <li>Suche den Empfänger.</li>
            <li>Es wird die Liste der vorhanden Pakete für diesen Kunden angezeigt.</li>
            <li>Klicke auf 'Scannen'.</li>
            <li>Hole die Pakete aus dem Lager und scanne deren Barcode.</li>
            <li>Klicke am Ende auf 'Fertig'.</li>
            <li>Prüfe nochmal die Daten auf der folgenden Seite.</li>
            <li>Klicke dann 'Ausliefern '.</li>
          </ul>
        </De>
        <En>
          <ul>
            <li> Open 'Loading for delivery' in the app. </li>
            <li> Find the recipient. </li>
            <li> The list of available packages for this customer is displayed. </li>
            <li> Click on 'Scan'. </li>
            <li> Get the parcels from the warehouse and scan their barcode. </li>
            <li> Click on 'Done' at the end. </li>
            <li> Check the data on the following page again. </li>
            <li> Then click 'Deliver'. </li>
          </ul>
        </En>
        <Fr>
          <ul>
            <li> Ouvrez "Chargement pour livraison" dans l'application. </li>
            <li> Recherchez le destinataire. </li>
            <li> La liste des packages disponibles pour ce client s'affiche. </li>
            <li> Cliquez sur 'Scannez'. </li>
            <li> Récupérez les colis de l'entrepôt et scannez leur code-barres. </li>
            <li> Cliquez sur "Fini" à la fin. </li>
            <li> Vérifiez à nouveau les données sur la page suivante. </li>
            <li> Cliquez ensuite sur 'Livrer'. </li>
          </ul>
        </Fr>
      </Workflow>
      <Workflow title="HandOver" wfid="">
        <De>
          <ul>
            <li>Öffne in der App 'Geliefert'.</li>
            <li>Suche den Empfänger.</li>
            <li>Es wird die Liste der vorhanden Pakete für diesen Kunden angezeigt.</li>
            <li>Klicke auf 'Scannen'.</li>
            <li>Hole die Pakete aus dem Fahrzeug und scanne deren Barcode.</li>
            <li>Klicke am Ende auf 'Fertig'.</li>
            <li>Prüfe nochmal die Daten auf der folgenden Seite.</li>
            <li>Klicke dann 'Übergeben'.</li>
          </ul>
        </De>
        <En>
          <ul>
            <li> Open 'Delivered' in the app. </li>
            <li> Find the recipient. </li>
            <li> The list of available packages for this customer is displayed. </li>
            <li> Click on 'Scan'. </li>
            <li> Get the parcels out of the vehicle and scan their barcode. </li>
            <li> Click on 'Done' at the end. </li>
            <li> Check the data on the following page again. </li>
            <li> Then click 'Hand over'. </li>
          </ul>
        </En>
        <Fr>
          <ul>
            <li> Ouvrez "Livré" dans l'application. </li>
            <li> Recherchez le destinataire. </li>
            <li> La liste des packages disponibles pour ce client s'affiche. </li>
            <li> Cliquez sur 'Scannez'. </li>
            <li> Sortez les colis du véhicule et scannez leur code-barres. </li>
            <li> Cliquez sur "Fini" à la fin. </li>
            <li> Vérifiez à nouveau les données sur la page suivante. </li>
            <li> Cliquez ensuite sur 'Remettre'. </li>
          </ul>
        </Fr>
      </Workflow>
    </div>
  )
}

export default Workflows

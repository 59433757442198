import i18next from 'i18next';

const De = ({ children }) => {
    if (i18next.language !== 'de' || !children) {
        return null;
    }
    return children;
}

export default De

import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

export default function ButtonCancel({ onCancel }) {
    const { t } = useTranslation();
    return (
        <Button variant="secondary" onClick={onCancel}>
            {t("Cancel")}
        </Button>
    )
}

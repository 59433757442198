import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Server } from '../../common/server.component'
import ButtonBar from '../../components/button-bar/button-bar.component'
import CustomerEditField from '../../components/customer-edit-field/customer-edit-field.component'
import OrderInfo from '../../components/order-info/order-info.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenSubTitle from '../../components/screen-sub-title/screen-sub-title.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import StringInput from '../../components/string-input/string-input.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSave, faLock } from '@fortawesome/free-solid-svg-icons'
import { notify } from 'react-notify-toast'

import styles from './tour-planning.module.css'

const TourPlanningScreen = () => {
  const [state, setState] = useState('initial')
  const [items, setItems] = useState([])
  const [error, setError] = useState()
  const [tourId, setTourId] = useState()
  const [tourName, setTourName] = useState('')
  const [orders, setOrders] = useState([])
  const [sender, setSender] = useState()
  const [recipient, setRecipient] = useState()
  const [packageCount, setPackageCount] = useState(1)

  const { qr, tours } = useContext(Server)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    tours.load(data => {
      setItems(data)
      if (data.length === 0) {
        setState('planning')
      } else {
        const p = data.filter(t => t.status === 'planning').length
        if (p.length === 0) {
          setState('planning')
        } else {
          setState('selecting')
        }
      }
    }, setError)
  }, [tours])

  if (state === 'initial') {
    return (
      <ScreenPanel>
        <ScreenTitle label="Wait" />
      </ScreenPanel>
    )
  }

  const save = () => {
    setError(null)
    if (!sender || !recipient || !packageCount) {
      setError('Angaben fehlen')
    }
    if (!tourId) {
      tours.create(
        {
          tourName,
          sender,
          recipient,
          packageCount,
        },
        tour => {
          setTourId(tour.id)
          setOrders(tour.orders)
          setSender(null)
          setRecipient(null)
          setPackageCount('1')
        }
      )
    } else {
      tours.addOrder(
        tourId,
        {
          tourName,
          sender,
          recipient,
          packageCount,
        },
        tour => {
          setOrders(tour.orders)
          setSender(null)
          setRecipient(null)
          setPackageCount('1')
        },
        setError
      )
    }
  }

  const selectTour = tour => {
    setState('planning')
    setTourId(tour.id)
    setTourName(tour.name)
    setOrders(tour.orders)
  }

  const closeTour = tour => {
    tours.close(
      tour.id,
      () => {
        notify.show(t('TourClosed'), 'success', 3000)
        tours.load(data => {
          setItems(data)
          if (data.length === 0) {
            setState('planning')
          } else {
            const p = data.filter(t => t.status === 'planning').length
            if (p.length === 0) {
              setState('planning')
            } else {
              setState('selecting')
            }
          }
        }, setError)
      },
      setError
    )
  }

  const newTour = () => {
    setState('planning')
  }

  const handleClick = () => {
    qr.download(setError)
  }
  const createDocs = () => {
    tours.createDocuments(tourId, setError)
  }

  if (state === 'planning') {
    return (
      <ScreenPanel>
        <ScreenTitle label="TourPlanning" />
        <StringInput label="TourName" value={tourName} onChange={setTourName} />
        <ScreenSubTitle label="Orders" />
        {orders.length === 0 && <div>{t('NoOrder')}</div>}
        {orders.map(order => (
          <OrderInfo key={order} orderId={order} setError={setError} onSelect={order => history.push(`/orders/${order.id}`)} />
        ))}
        <div className={styles.orderRow}>
          <CustomerEditField customClass={styles.customer} label="Sender" customer={sender} onChange={setSender} />
          <CustomerEditField customClass={styles.customer} label="Recipient" customer={recipient} onChange={setRecipient} />
          <StringInput customClass={styles.count} label="PackageCount" value={packageCount} onChange={setPackageCount} />
          <Button onClick={save} disabled={!sender || !recipient}>
            {' '}
            <FontAwesomeIcon icon={faSave} title={t('Save')} size="3x" />{' '}
          </Button>
        </div>
        {error && <Alert variant="danger">{JSON.stringify(error)}</Alert>}
        <ButtonBar>
          <Button onClick={() => handleClick()}>{t('CreateQRs')}</Button>
          <Button onClick={createDocs}>{t('CreateDocs')}</Button>
        </ButtonBar>
      </ScreenPanel>
    )
  }

  return (
    <ScreenPanel>
      <ScreenTitle label="TourPlanning" />
      <ScreenSubTitle label="ToursPlanned" />
      {items.map(item => (
        <div key={item.id} className={styles.planned}>
          <span>{item.name}</span>
          <span>
            <Button
              onClick={() => {
                selectTour(item)
              }}
            >
              <FontAwesomeIcon icon={faPen} title={t('PlanContinue')} />
            </Button>
            <Button
              onClick={() => {
                closeTour(item)
              }}
            >
              <FontAwesomeIcon icon={faLock} title={t('PlanClose')} />
            </Button>
          </span>
        </div>
      ))}
      <hr />
      <ButtonBar>
        <Button onClick={newTour}>{t('TourCreate')}</Button>
        <Button onClick={handleClick}>{t('CreateQRs')}</Button>
      </ButtonBar>
    </ScreenPanel>
  )
}

export default TourPlanningScreen

import React from 'react';
import Input from '../input/input.component';

const PasswordInput = ({ label, value, onChange, onKeyPress }) => {
    return (
        <Input type="password" label={label} value={value} onChange={onChange} onKeyPress={onKeyPress} />
        )
}

export default PasswordInput;

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonBar from '../button-bar/button-bar.component';
import { useTranslation } from 'react-i18next';
import ItemList from '../item-list/item-list.component';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import StatusSelect from '../status-select/status-select.component';

const ContainerEvents = ({ container, onAdd }) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [status, setStatus] = useState();
    const [message, setMessage] = useState('');

    return <>
        <div>
            {
                container.events ? (<ItemList>
                    { container.events.map(item => <ListGroup.Item key={item.created}>
                        {eventText(item)}</ListGroup.Item>)}
                </ItemList>) : <div>{t("NoData")}</div>
            }
            <ButtonBar>
                <Button variant="primary" onClick={() => setOpened(true)}>{t("Add")}</Button>
            </ButtonBar>
        </div>
        <Modal show={opened}>
            <Modal.Header>
                <Modal.Title>{t("AddEvent")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="status">
                    <StatusSelect placeholder="Status" value={status} onChangeText={setStatus} />
                </Form.Group>
                <Form.Group controlId="message">
                    <Form.Control type="text" placeholder={t("Message")} value={message} onChange={({ target }) => setMessage(target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpened(false)}>{t("Cancel")}</Button>
                <Button variant="primary" onClick={() => {
                    setOpened(false);
                    onAdd(status.statusId, message);
                }}>{t("Ok")}</Button>
            </Modal.Footer>
        </Modal>
    </>;
}

function eventText(event) {
    if (!event) {
        return null;
    }
    const p = event.created ? event.created.indexOf('T') : -1;
    const c = p !== -1 ? event.created.substring(0, p) : event.created;
    return c + " - " + event.status + " - " + event.message;
}

export default ContainerEvents;

import React from 'react'
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error = ({ error }) => {
    const { t } = useTranslation();
    if (!error) {
        return null;
    }
    var txt = null;
    if (error.code) {
        txt = t(`Error-${error.code}`);
    } else if (error.message) {
        txt = error.message;
    } else if (typeof error === 'string') {
        txt = error;
    } else {
        txt = JSON.stringify(error);
    }
    return (
        <Alert variant="danger">{txt}</Alert>
    )
}

export default Error

import React from 'react';
import Container from 'react-bootstrap/Container';
import CheckAccess from '../check-access/check-access.component';

import styles from './screen-panel.module.css';

const ScreenPanel = ({ children }) => {
    return (
        <CheckAccess>
            <Container className={styles.screenPanel}>
                {children}
            </Container>
        </CheckAccess>
    )
}

export default ScreenPanel;

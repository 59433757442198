import React, { useContext, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useTranslation } from 'react-i18next';
import { Server } from "../../common/server.component";
import ScreenPanel from "../../components/screen-panel/screen-panel.component";

const QrScreen = () => {
    const { qr } = useContext(Server);
    const [error, setError] = useState();
    const { t } = useTranslation();

    const handleClick = () => {
        qr.download(setError);
    };

    return (
        <ScreenPanel>
            <Button onClick={() => handleClick()}>{t('CreateQRs')}</Button>
            {error && <Alert variant="danger">{error}</Alert>}
        </ScreenPanel>
    )
}

export default QrScreen

import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './screen-title.module.css';

const ScreenTitle = ({ label }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.screenTitle}>
            {t(label)}
        </div>
    )
}

export default ScreenTitle;

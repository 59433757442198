import React from 'react'

const CustomerDisplayField = ({customer}) => {
    if (!customer) return null;
    var name = customer.lastname;
    if (customer.firstname) {
        name = name ? name + ", " + customer.firstname : "";
    }
    var id = customer?.customerId;
    if (id) {
        if (name) {
            id += " - " + name;
        }
    } else {
        id = name;
    }
    return <span>{id}</span>;
}

export default CustomerDisplayField;
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import styles from './key-value.module.css';

class KeyValueRow extends Component {
    render() {
        const { t } = this.props;
        return <Row className={styles.keyValue}>
            <Col>{t(this.props.k)}:</Col>
            <Col>{this.props.children}</Col>
        </Row>;
    }
}

export default withTranslation()(KeyValueRow);

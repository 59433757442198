import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './invoice-item-info.module.css'

const InvoiceItemInfo = ({ item, header }) => {
  const { t } = useTranslation()
  if (header) {
    return (
      <div className={styles.header}>
        <div className={styles.invoiceItemInfo}>
          <span>{t('Text')}</span>
          <span>{t('Count')}</span>
          <span>{t('Amount')}</span>
          <span>{t('Total')}</span>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.invoiceItemInfo}>
      <span>{item.text}</span>
      <span>{item.count}</span>
      <span>{item.amount}</span>
      <span>{item.total}</span>
    </div>
  )
}

export default InvoiceItemInfo

import React, { useContext, useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../backend/ErrorMessage';
import { Server } from '../../common/server.component';
import ButtonBar from '../button-bar/button-bar.component';

const InquiryCustomers = (props) => {
    const { inquiries } = useContext(Server);
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [data, setData] = useState();
    const [selectedAccount, setSelectedAccount] = useState();
    const inquiryId = props.data.id;

    useEffect(() => {
        inquiries.customers.get(inquiryId, setData, setError);
    }, [inquiries, inquiryId]);

    if (!data) {
        return <ErrorMessage error={error} />;
    }

    const renderAccount = (account) => {
        const active = selectedAccount === account.account.id
        return <ListGroup.Item key={account.account.id} active={active}
            onClick={() => {
                setSelectedAccount(active ? null : account.account.id);
                setError(null);
            }}>
            {account.account.lastname}, {account.account.firstname} ({account.account.company}), {account.account.city}
        </ListGroup.Item>
    }

    const createAll = () => {
        inquiries.orders.create({ inquiry: inquiryId }, (response) => { }, setError);
    }

    const createOrder = () => {
        if (selectedAccount) {
            inquiries.orders.create({ inquiry: inquiryId, account: selectedAccount }, (response) => { }, setError);
        } else {
            setError("Kein Kunde ausgewählt");
        }
    }

    const createOrderAccountId = () => {
        inquiries.orders.create({ inquiry: inquiryId, account: data[0].account.id }, (response) => { }, setError);
    }

    var body = null;
    if (data.length === 0) {
        body = <>
            <div>{t("NoCustomerFound")}</div>
            <ButtonBar>
                <Button onClick={() => createAll()}>{t("CreateCustomerAndOrder")}</Button>
            </ButtonBar>
        </>;
    } else if (data.length === 1 && data[0].similarity === 20) {
        body = <>
            <div>{t("CustomerFoundByAccountID")}</div>
            <ListGroup>
                {renderAccount(data[0])}
            </ListGroup>
            <ButtonBar>
                <Button onClick={() => createOrderAccountId()}>{t("CreateOrder")}</Button>
            </ButtonBar>
        </>;
    } else {
        body = <>
            <div>{t("CustomersFoundByName")}</div>
            <ListGroup>
                {data.map(account => renderAccount(account))}
            </ListGroup>
            <ButtonBar>
                <Button variant="primary" onClick={() => createAll()}>{t("CreateCustomerAndOrder")}</Button>{" "}
                <Button variant="secondary" onClick={() => createOrder()}>{t("CreateOrderForCustomer")}</Button>
            </ButtonBar>
        </>;
    }
    return <>
        <ErrorMessage error={error} />
        {body}
    </>;
}

export default InquiryCustomers;

import React from 'react';
import { useHistory } from 'react-router';
import styles from './user.module.css';

const toRolename = code => {
    switch (code ? code : "") {
        case 'admin':
            return "Administrateur";
        case 'user':
            return "Utilisateur";
        case 'guest':
            return "Visiteur";
        default:
            return "Aucune";
    }
}

const User = ({ user }) => {
    const role = user.roles ? user.roles[0] : null;
    const history = useHistory();

    return (
        <div className={styles.user} onClick={() => history.push(`/users/${user.id}`)}>
            <div>{user.displayName}</div>
            <div className={styles.email}>{user.email}</div>
            <div>{toRolename(role)}</div>
        </div >
    )
}

export default User;
